import React from "react";

export function useIsMounted(): React.MutableRefObject<boolean> {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}
