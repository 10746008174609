import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React from "react";
import { OverrideScript, ScriptFactory } from "../../../common/Script";
import { Order } from "./Comparator";

export interface SortableTableHeadProps<T> {
  script: OverrideScript<T>;
  numSelected?: number;
  order?: Order;
  orderBy?: keyof T | undefined;
  rowCount?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete?: (ids: string[]) => void;
  useStyles: (props?: unknown) => ClassNameMap<string>;
}

export default function SortableTableHead<T>(
  props: SortableTableHeadProps<T>
): JSX.Element {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    script,
    onSelectAllClick,
    onRequestSort,
    useStyles,
  } = props;
  const classes = useStyles();
  function createSortHandler<K extends keyof T>(property: K) {
    return (event: React.MouseEvent<unknown>) => {
      onRequestSort?.(event, property);
    };
  }

  return (
    <TableHead>
      <TableRow>
        {onSelectAllClick && numSelected && rowCount && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all items" }}
            />
          </TableCell>
        )}
        {script.map((verse) => {
          const { id, padding, text } = verse;
          const rest = ScriptFactory.getHTMLProps(verse);
          return (
            <TableCell
              key={id}
              sortDirection={orderBy === id ? order : false}
              padding={padding ?? "normal"}
              {...rest}
            >
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : "asc"}
                onClick={createSortHandler<keyof T>(id as keyof T)}
              >
                {text}
                {orderBy === id ? (
                  <span className={classes?.visuallyHidden ?? ""}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
