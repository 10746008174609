import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { DropdownSelectorOption, IInvoiceObject } from "../common/interfaces";
import DropdownSelect from "../components/Input/DropdownSelect";
import { InvoiceContext } from "../context/InvoiceContext";
import { SchoolContext } from "../context/SchoolContext";
import BasicView from "./BasicView";
import clsx from "clsx";
import FileSaver from "file-saver";
import { API } from "../context/APIContext";
import Toaster, { ToastType } from "../common/Toaster";
import { createLogger } from "../components/Logging/Logging";
import { getContentType, isContentCSV, isContentJSON } from "../common/Utils";
import { DateTime } from "luxon";
import ConfirmationModal from "../components/ConfirmationModal";
import { WarningRoundedIcon } from "../icons";
import InvoiceTable from "../components/InvoiceTable";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  dimmed: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  centeringColumn: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  totals: {
    padding: "auto",
  },
  button: {
    marginTop: "10px",
  },
  link: {
    color: "white",
    "$:hover": {
      color: "white",
    },
  },
  datePicker: {
    maxWidth: "165px",
  },
  someMargin: {
    margin: "8px",
  },
  inlineContainer: {
    display: "inline",
  },
}));

const logger = createLogger("InvoiceView");

export default function InvoiceView(): JSX.Element {
  const schoolContext = React.useContext(SchoolContext);
  const invoiceContext = React.useContext(InvoiceContext);
  const { filteredInvoices, setSchool, school } = invoiceContext;
  const [showInvoiceDeleteDialog, setShowInvoiceDeleteDialog] =
    React.useState<boolean>(false);
  const [showConfirmInvoiceDelete, setShowConfirmInvoiceDelete] =
    React.useState<boolean>(false);
  const [invoiceDeleteNumber, setInvoiceDeleteNumber] =
    React.useState<string>("");
  const options = schoolContext.schools.map((school) => ({
    label: `${school.name} ${school.invoicePrefix}`,
    value: school.id,
  }));
  const classes = useStyles();
  const api = React.useContext(API);

  const downloadInvoice = React.useCallback(
    (inv: IInvoiceObject) => {
      api
        .getInvoiceById({
          id: inv._id,
        })
        .then(async (res) => {
          await res
            .json()
            .then((jobj) => {
              if (res.ok) {
                const incoming_invoice = JSON.parse(jobj);
                if (
                  incoming_invoice === undefined ||
                  incoming_invoice.blob === undefined
                ) {
                  throw new Error(
                    "Error: Invoice returned from server undefined"
                  );
                }
                const invoiceNumber = inv.invoiceNumber;
                FileSaver.saveAs(
                  "data:application/pdf;base64," + incoming_invoice.blob,
                  `invoice_${invoiceNumber}.pdf`
                );
              } else {
                logger.error(
                  `ERROR Code ${res.status}: ${res.statusText} => Render Error`,
                  res
                );
              }
            })
            .catch((err) => {
              Toaster(err.message, ToastType.error);
              logger.error(err);
            });
        })
        .catch((err) => {
          logger.error(err);
        });
    },
    [api]
  );

  const exportInvoice = React.useCallback(
    (inv: IInvoiceObject) => {
      api.getInvoiceExportCSV({ invoiceIds: [inv._id] }).then(async (res) => {
        if (res.ok) {
          if (isContentCSV(res)) {
            const text = await res.text();
            const fname = `${
              schoolContext.schools
                .find((sch) => sch.id === inv.schoolId)
                ?.name.replaceAll(" ", "_") ?? "Unknown"
            }_export_${DateTime.local()
              .toLocaleString()
              .replace("/", "_")}.csv`;

            FileSaver.saveAs(new Blob([text]), fname);
          } else {
            Toaster(
              `Server Returned Unexpected Content Type: ${getContentType(res)}`,
              ToastType.error
            );
            logger.error("Unexpected Content Type", { res, inv });
          }
        } else {
          switch (res.status) {
            case 406:
              Toaster(
                `Error: Invalid Request from Client: ${res.statusText}`,
                ToastType.error
              );
              logger.error(`${res.status}: ${res.statusText}`, { res, inv });
              break;
            default:
              Toaster(
                `Unknown Error Occured: ${res.statusText}`,
                ToastType.error
              );
              logger.error(`${res.status}: ${res.statusText}`, { res, inv });
          }
        }
      });
    },
    [api, schoolContext.schools]
  );

  const handleSchoolChange = React.useCallback(
    (option: DropdownSelectorOption) => setSchool(option.value),
    [setSchool]
  );

  const invoiceDeleteDialogMessage = React.useMemo(() => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography variant="h6">Enter Invoice Number to Delete</Typography>
        <TextField
          label={"Invoice Number"}
          variant="outlined"
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setInvoiceDeleteNumber(e.target.value);
          }}
          value={invoiceDeleteNumber}
        />
      </div>
    );
  }, [invoiceDeleteNumber]);

  const handleInitialInvoiceDelete = React.useCallback(() => {
    if (invoiceDeleteNumber === "") {
      Toaster("Invoice Number cannot be empty.", ToastType.error);
      return;
    }
    const invoiceToDelete = invoiceContext.invoices.find(
      (inv: IInvoiceObject) => inv.invoiceNumber === invoiceDeleteNumber
    );

    if (invoiceToDelete === undefined) {
      Toaster("There is no such invoice with that number.", ToastType.error);
      return;
    }

    setShowConfirmInvoiceDelete(true);
  }, [invoiceContext.invoices, invoiceDeleteNumber]);

  const confirmInvoiceDelete = React.useCallback(() => {
    api
      .deleteInvoice({ invoiceId: invoiceDeleteNumber })
      .then((res) => {
        if (res.status === 200) {
          Toaster(
            `${invoiceDeleteNumber} deleted sucessfully.`,
            ToastType.good
          );
        } else if (res.status === 404) {
          Toaster(
            "Cannot find endpoint, possible that the backend is down or out of date.",
            ToastType.error
          );
        } else {
          if (isContentJSON(res)) {
            res.json().then((j) => {
              Toaster(`Error ${j.message}`, ToastType.error);
            });
          }
        }
      })
      .catch((err: unknown) => {
        Toaster(`Unknown Error: ${err}`, ToastType.error);
        console.log(err);
      });

    setInvoiceDeleteNumber("");
    setShowInvoiceDeleteDialog(false);
    setShowConfirmInvoiceDelete(false);
    window.location.reload();
  }, [api, invoiceDeleteNumber]);

  const cancelInvoiceDelete = React.useCallback(() => {
    setShowInvoiceDeleteDialog(false);
    setShowConfirmInvoiceDelete(false);
    setInvoiceDeleteNumber("");
  }, []);

  return (
    <>
      <BasicView>
        <Grid item xs={12}>
          <Paper className={clsx(classes.paper, classes.totals)}>
            <div>
              <Typography
                variant="h5"
                component="h6"
                style={{ display: "inline" }}
              >
                {school === "All"
                  ? ` All Districts`
                  : " " +
                      schoolContext.schools.find((s) => s.id === school)
                        ?.name ?? ""}
              </Typography>
              <Button
                style={{ float: "right" }}
                variant="outlined"
                color="primary"
                onClick={() => setShowInvoiceDeleteDialog(true)}
              >
                Delete Invoice
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div>
              <Grid item xs={12}>
                <div className={classes.inlineContainer}>
                  <div className={clsx(classes.inlineContainer)}>
                    <DropdownSelect
                      label={"School"}
                      value={{
                        label: school,
                        value: school,
                      }}
                      handleChange={(ns) => {
                        if (ns) handleSchoolChange(ns);
                        else setSchool("All");
                      }}
                      options={options}
                      includeAll={true}
                      isClearable={true}
                    />
                  </div>
                </div>
              </Grid>
              <br />
              <Grid item xs={12}>
                <InvoiceTable
                  invoices={filteredInvoices}
                  onDownload={downloadInvoice}
                  onExport={exportInvoice}
                />
              </Grid>
            </div>
          </Paper>
        </Grid>
        <ConfirmationModal
          confirmText="Cancel"
          cancelText="Delete"
          onConfirm={cancelInvoiceDelete}
          onCancel={handleInitialInvoiceDelete}
          open={showInvoiceDeleteDialog}
          setOpen={cancelInvoiceDelete}
          message={invoiceDeleteDialogMessage}
        />
        <ConfirmationModal
          message={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <WarningRoundedIcon
                  fontSize="large"
                  style={{ marginRight: "10px" }}
                />
                <Typography align="center">
                  This operation is <b>irreversible!</b> Are you sure you want
                  to delete this invoice?
                </Typography>
              </div>
              <Typography variant="h4">{invoiceDeleteNumber}</Typography>
            </div>
          }
          confirmText={`Yes, delete ${invoiceDeleteNumber}`}
          cancelText="No, do not delete invoice"
          onConfirm={confirmInvoiceDelete}
          onCancel={cancelInvoiceDelete}
          open={showConfirmInvoiceDelete}
          setOpen={cancelInvoiceDelete}
        />
      </BasicView>
    </>
  );
}
