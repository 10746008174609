import React from "react";
import { Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "../auth/protect-route";
import BillingView from "../views/BillingView";
import DistrictView from "../views/DistrictView";
import IndividualStaffView from "../views/IndividualStaffView";
import InvoiceView from "../views/InvoiceView";
import ServicesView from "../views/ServicesView";
import StaffOverview from "../views/StaffOverview";

export default function PageSwitcher(): JSX.Element {
  return (
    <Switch>
      <Redirect exact from={"/"} to={"/billing"} />
      <ProtectedRoute exact path="/billing" component={BillingView} />
      <ProtectedRoute exact path="/districts" component={DistrictView} />
      <ProtectedRoute exact path="/staff" component={StaffOverview} />
      <ProtectedRoute path="/staff/:id" component={IndividualStaffView} />
      <ProtectedRoute exact path="/services" component={ServicesView} />
      <ProtectedRoute exact path="/invoices" component={InvoiceView} />
    </Switch>
  );
}
