import React from "react";
import { ITherapist } from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { isContentHTML, isContentJSON } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { API } from "./APIContext";
import Toaster, { ToastType } from "../common/Toaster";

interface ITherapistContext {
  triggerRefresh: () => void;
  therapists: ITherapist[];
  readonly selectedTherapist: ITherapist | undefined;
  setSelectedTherapist: (therapist: ITherapist | undefined) => void;
  isLoading: boolean;
}

interface ITherapistContextProviderProps {}

const defaultState = {
  triggerRefresh: () => null,
  therapists: [],
  selectedTherapist: undefined,
  setSelectedTherapist: () => null,
  isLoading: true,
};

export const TherapistContext =
  React.createContext<ITherapistContext>(defaultState);

const logger = createLogger("TherapistContext");

export default function TherapistContextProvider(
  props: React.PropsWithChildren<ITherapistContextProviderProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = React.useContext(API);

  const [state, setState] = React.useState<ITherapistContext>(defaultState);
  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);
  const [selectedTherapist, setSelectedTherapist] =
    React.useState<ITherapist>();
  React.useEffect(
    () =>
      setState((prev) => ({
        ...prev,
        triggerRefresh: () => setRefreshTrigger((prev) => prev + 1),
        setSelectedTherapist: (therapist: ITherapist | undefined) =>
          setSelectedTherapist(therapist),
      })),
    []
  );

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      selectedTherapist,
    }));
  }, [selectedTherapist]);

  React.useEffect(() => {
    try {
      api
        .getTherapists({})
        .then(async (res) => {
          if (isContentJSON(res)) {
            res
              .json()
              .then((j) => {
                if (res.status === 200) {
                  const data = j as ITherapist[];
                  if (isMounted.current)
                    setState((prev) => ({
                      ...prev,
                      selectedTherapist: data.find(
                        (t: ITherapist) =>
                          t.userId === selectedTherapist?.userId
                      ),
                      therapists: data,
                      isLoading: false,
                    }));
                } else {
                  logger.error(
                    `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                    {
                      res,
                      j,
                    }
                  );
                  Toaster(
                    `An error occurred: ${res.status} ${res.statusText} ${j.message}`,
                    ToastType.error
                  );
                }
              })
              .catch((e) => {
                logger.error(e);
                Toaster(`${e}`, ToastType.error);
              });
          } else if (isContentHTML(res)) {
            logger.error(
              `ERROR Code ${res.status}: ${res.statusText} => ${res.text()}`
            );
            // Toaster(
            //   `An error occurred: ${res.status} ${res.statusText}`,
            //   ToastType.error
            // );
          }
        })
        .catch((e) => {
          logger.error(e);
          Toaster(`${e}`, ToastType.error);
        });
    } catch (error) {
      logger.error("unknown error", error);
      Toaster(`An unknown error occurred: ${error}`, ToastType.error);
    }
  }, [api, isMounted, refreshTrigger, selectedTherapist?.userId]);

  React.useEffect(() => {
    logger.info(`loaded = ${!state.isLoading}`);
  }, [state.isLoading]);

  return (
    <TherapistContext.Provider value={state}>
      {props.children}
    </TherapistContext.Provider>
  );
}
