import React from "react";
import LogoutButton from "../LogoutButton";

interface ErrorPageProps {
  errorMessage?: string;
  code?: number;
}

export function ErrorPage(props: ErrorPageProps): JSX.Element {
  const { errorMessage, code } = props;

  const mapCode = (code: number | undefined) => {
    if (!code) return undefined;
    switch (code) {
      case 404:
        return "404: Not Found";
      default:
        return code;
    }
  };

  const _errorMessage = React.useMemo(
    () => errorMessage ?? "Error",
    [errorMessage]
  );
  const _code = React.useMemo(() => mapCode(code) ?? "", [code]);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: "linear-gradient(-105deg, #009acc, #363795)",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", right: "20px", top: "15px" }}>
        <LogoutButton />
      </div>
      <div style={{ margin: "auto", display: "flex", flexDirection: "column" }}>
        <img
          style={{ margin: "0 auto" }}
          width="20%"
          src={`${process.env.PUBLIC_URL}/errorBoundryImage.svg`}
        />
        <div
          style={{
            fontSize: 36,
            fontWeight: "bold",
            fontFamily: "monospace",
            color: "white",
            width: "80%",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <div style={{ paddingBottom: "15px" }}>
            {_code}
            <br />
            {_errorMessage}
          </div>
        </div>
      </div>
    </div>
  );
}
