import React from "react";
import { SchoolContext } from "../../context/SchoolContext";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PredefinedStudentSelector from "./PredefinedStudentSelector";
import {
  DropdownSelectorOption,
  ISchool,
  IStudent,
  ITherapist,
  IWorkItem,
  IPartialWorkItem,
} from "../../common/interfaces";
import { WorkItemContext } from "../../context/WorkItemContext";
import { SettingsContext } from "../../context/SettingsContext";
import Toaster, { ToastType } from "../../common/Toaster";
import { WorkItemSelector } from "./WorkItemSelector";
import { Paper, Typography, FormControlLabel, Switch } from "@material-ui/core";
import { ConfirmDenyButtonAction } from "./ConfirmDenyButtonAction";
import { SimpleDatePicker } from "./SimpleDatePicker";
import { API } from "../../context/APIContext";
import { DateTime } from "luxon";
import { formatISO } from "../../common/DateUtils";
import { isObjectEmpty } from "../../common/Utils";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "90%",
    margin: "5px auto",
    minWidth: "10%",
  },
  centeringColumn: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  centeringRow: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: "400px",
    marginBottom: "10px",
  },
  inline: {
    display: "inline",
    width: "90%",
    margin: "auto",
  },
  slim: {
    width: "48%",
    marginTop: "8px",
  },
  spacer: {
    width: "18px",
    display: "inline-block",
  },
  button: {
    marginTop: "5px",
    width: "150px",
  },
  title: {
    flexGrow: 1,
  },
  right: {
    float: "right",
  },
  left: {
    float: "left",
  },
  table: {
    padding: "0",
  },
  label: {
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    margin: "auto",
  },
  tableTitle: {
    padding: "5px",
    marginLeft: "10px",
  },
  autoMargin: {
    margin: "auto",
  },
  dense: {
    height: "50px",
  },
}));

interface IWorkItemFormProps {
  therapist: ITherapist;
  setOpen?: (val: boolean) => void;
}

export interface IValidationState {
  type: boolean;
  school: boolean;
  units: boolean;
  students: boolean;
}

const defaultValidationState = {
  type: false,
  school: false,
  units: false,
  students: false,
};

export default function WorkItemForm(props: IWorkItemFormProps): JSX.Element {
  const { therapist, setOpen } = props;
  const schoolContext = React.useContext(SchoolContext);
  const { classes } = React.useContext(SettingsContext);
  const { makeDirty } = React.useContext(WorkItemContext);
  const api = React.useContext(API);

  const [school, setSchool] = React.useState<ISchool | undefined>(undefined);
  const [selectedStudents, setSelectedStudents] = React.useState<IStudent[]>(
    []
  );
  const [dateWorked, setDateWorked] = React.useState<DateTime>(
    DateTime.local()
  );
  const [validationState, setValidationState] =
    React.useState<IValidationState>(defaultValidationState);
  const [additionalItems, setAdditionalItems] = React.useState<
    IPartialWorkItem[]
  >([]);

  const [multiStudent, setMultiStudent] = React.useState<boolean>(false);

  const schools = React.useMemo(() => {
    if (therapist) {
      return schoolContext.schools.filter((s) =>
        therapist.schoolIds.includes(s.id)
      );
    } else {
      return [];
    }
  }, [therapist, schoolContext]);

  const students: IStudent[] = React.useMemo(() => {
    return school?.students ?? [];
  }, [school]);

  const sortedStudents = React.useMemo(() => {
    return students.sort((a, b) => a.name.localeCompare(b.name));
  }, [students]);

  const workTypes = React.useMemo(() => {
    if (therapist && !isObjectEmpty(classes)) {
      return classes[therapist.classId].workTypes;
    } else {
      return {};
    }
  }, [therapist, classes]);

  const clearStates = React.useCallback(() => {
    setSchool(undefined);
    setSelectedStudents([]);
    setValidationState(defaultValidationState);
  }, []);

  const addStudent = React.useCallback(
    (input: IStudent) => {
      if (selectedStudents.length === 0) {
        setValidationState((prev) => ({ ...prev, students: true }));
      }
      if (!selectedStudents.includes(input))
        setSelectedStudents((prev) => [...prev, input]);
    },
    [selectedStudents]
  );

  const removeStudent = React.useCallback(
    (input: IStudent) => {
      if (selectedStudents.length === 1) {
        setValidationState((prev) => ({ ...prev, students: false }));
      }
      setSelectedStudents(selectedStudents.filter((s) => s !== input));
    },
    [selectedStudents]
  );

  const setStudent = React.useCallback(
    (stud: IStudent) => setSelectedStudents([stud]),
    []
  );
  const style = useStyles();

  const handleSubmission = React.useCallback(() => {
    if (!therapist) return;
    if (therapist?.name?.length === 0) {
      Toaster("Name field cannot be empty");
      return;
    }
    if (additionalItems.length === 0) {
      Toaster("No Work Items Added", ToastType.error);
      return;
    }

    if (!school) {
      Toaster("District Name Required", ToastType.error);
      return;
    }

    const schoolId = school?.id;
    const dateLoggedString = formatISO(DateTime.local());
    const dateWorkedString = formatISO(dateWorked);
    const studs = students.filter((s) => selectedStudents.includes(s));
    const payload = additionalItems.map(
      (ai) =>
        ({
          schoolId,
          dateLogged: dateLoggedString,
          dateWorked: dateWorkedString,
          classId: ai.classId,
          workTypeId: ai.workTypeId,
          students: studs,
          units: Number(ai.unit),
          therapistUserId: therapist.userId,
        } as IWorkItem)
    );

    const workTypeRequiresStudents = payload.some(
      (item) => classes[item.classId].workTypes[item.workTypeId].requireStudents
    );
    if (workTypeRequiresStudents && studs.length === 0) {
      Toaster(
        "One or more selected work types require students selected.",
        ToastType.error
      );
      return;
    }
    Toaster(`Processing...`, ToastType.info);
    api.storeWorkItem([...payload]).then(
      async (res) =>
        await res.json().then((json) => {
          if (res.status === 200) {
            Toaster(
              payload.length === 1
                ? `Work Item Submitted`
                : `${payload.length} Work Items Submitted`,
              ToastType.good
            );
            makeDirty();
          } else {
            Toaster(`Failed to submit ${json.message}`, ToastType.error);
          }
        })
    );
    setSchool(undefined);
    setSelectedStudents([]);
    setStudent({} as IStudent);
    setAdditionalItems([]);
    setValidationState(defaultValidationState);
    setOpen?.(false);
  }, [
    classes,
    therapist,
    additionalItems,
    school,
    dateWorked,
    students,
    api,
    setStudent,
    setOpen,
    selectedStudents,
    makeDirty,
  ]);

  const setValidation = React.useCallback(
    (key: keyof IValidationState, value: boolean) => {
      setValidationState((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const addAdditionalItem = React.useCallback((item: IPartialWorkItem) => {
    setAdditionalItems((prev) => [...prev, item]);
  }, []);

  const handleChangeSchool = React.useCallback(
    (e: DropdownSelectorOption) => {
      setValidationState((prev) => ({ ...prev, school: true }));
      setSchool(
        schools.find((elem) => {
          return elem.id.localeCompare(e.value) === 0;
        })
      );
    },
    [setValidationState, setSchool, schools]
  );

  const removeAdditionalItem = React.useCallback(
    (id: number) => {
      setAdditionalItems(additionalItems.filter((ai) => ai.id !== id));
    },
    [additionalItems]
  );

  const studentSelector = React.useMemo(() => {
    return multiStudent ? (
      <PredefinedStudentSelector
        options={sortedStudents}
        selected={selectedStudents}
        addStudent={addStudent}
        removeStudent={removeStudent}
        paperClassName={style.input}
        error={!validationState.students}
      />
    ) : (
      <div
        style={{
          width: "92%",
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          paddingBottom: "5px",
        }}
      >
        <DropdownSelect
          label={"Student"}
          className={clsx(style.input)}
          options={makeDropdownOptions(sortedStudents, "name", "name")}
          value={{
            label: selectedStudents[0]?.name,
            value: selectedStudents[0]?.name,
          }}
          handleChange={(e: DropdownSelectorOption | null) => {
            if (e) {
              setValidation("students", true);
              setStudent(
                students.find((stu) => stu.name === e.value) ?? ({} as IStudent)
              );
            }
          }}
          error={!validationState.students}
          isClearable
          forcePlaceholder
        />
      </div>
    );
  }, [
    addStudent,
    style.input,
    multiStudent,
    removeStudent,
    selectedStudents,
    setStudent,
    setValidation,
    sortedStudents,
    students,
    validationState.students,
  ]);

  return (
    <Paper className={clsx(style.paper, style.centeringColumn)} elevation={3}>
      <Typography
        component="h3"
        variant="h6"
        color="inherit"
        noWrap
        className={style.title}
      >
        Submit Work Item
      </Typography>
      <SimpleDatePicker
        label={"DateTime Worked"}
        value={dateWorked}
        onChange={(DateTime) => {
          if (DateTime) setDateWorked(DateTime);
        }}
        className={style.input}
      />
      <div className={style.centeringColumn}>
        <div
          style={{
            width: "92%",
            display: "flex",
            flexDirection: "row",
            margin: "auto",
          }}
        >
          <DropdownSelect
            label={"District"}
            value={{
              label: schools.find((s) => school?.id === s.id)?.name ?? "",
              value: school?.id ?? "",
            }}
            handleChange={(ns) => {
              if (ns) handleChangeSchool(ns);
            }}
            options={makeDropdownOptions(schools, "name", "id")}
            className={clsx(style.input)}
            required
            error={!validationState.school}
            isClearable
            forcePlaceholder
          />
        </div>
        {studentSelector}
        <FormControlLabel
          control={
            <Switch
              checked={multiStudent}
              onChange={(e: unknown, checked: boolean) =>
                setMultiStudent(checked)
              }
              name="checkMultiStudent"
              color="primary"
            />
          }
          label="Multiple Students"
          className={style.input}
        />
        <div className={style.centeringColumn}>
          <WorkItemSelector
            validationState={validationState}
            workTypes={workTypes}
            classId={therapist.classId}
            dateWorked={dateWorked}
            additionalItems={additionalItems}
            setValidation={setValidation}
            setDateWorked={setDateWorked}
            addAdditionalItem={addAdditionalItem}
            removeAdditionalItem={removeAdditionalItem}
          />
          <ConfirmDenyButtonAction
            confirmText={"Attestation"}
            denyText={"Cancel"}
            confirmAction={handleSubmission}
            denyAction={() => {
              clearStates();
              props.setOpen?.(false);
            }}
          />
        </div>
      </div>
    </Paper>
  );
}
