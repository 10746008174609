import { Button, PropTypes } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

interface LogoutButtonProps {
  variant?: "text" | "outlined" | "contained";
  color?: PropTypes.Color;
}

export default function LogoutButton(props: LogoutButtonProps): JSX.Element {
  const { logout } = useAuth0();

  return (
    <Button
      variant={props.variant ?? "contained"}
      color={props.color}
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
      startIcon={<LockIcon fontSize='large'/>}
    >
      {' Logout'}
    </Button>
  );
}
