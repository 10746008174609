import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IDateRange, ITherapist, IWorkItem } from "../common/interfaces";
import { SchoolContext } from "../context/SchoolContext";
import { TherapistContext } from "../context/TherapistContext";
import SortableTable from "./Display/Table/SortableTable";
import { OverrideScript, OverrideVerse } from "../common/Script";
import {
  AssignmentTurnedInOutlinedIcon,
  DeleteIcon,
  EditIcon,
  WarningRoundedIcon,
} from "../icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Fab, Paper, Typography } from "@material-ui/core";
import { SingleWorkItemEditor } from "./Input/SingleWorkItemEditor";
import { SettingsContext } from "../context/SettingsContext";
import WorkItemFormModal from "./WorkItemFormModal";
import { clsx } from "../clsx";
import { RangeDatePicker } from "./RangeDatePicker/RangeDatePicker";
import ConfirmationModal from "./ConfirmationModal";
import { API } from "../context/APIContext";
import Toaster, { ToastType } from "../common/Toaster";
import { WorkItemContext } from "../context/WorkItemContext";
import { isContentHTML, isContentJSON, isContentText } from "../common/Utils";
import { createLogger } from "./Logging/Logging";
import { BoldNumberDisplay } from "./Display/BoldNumberDisplay";
import Eye from "./Input/Eye";
import { DateTime } from "luxon";

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "blue",
    },
  },
  fabMargin: {
    marginTop: "5px",
    marginRight: "10px",
  },
  fabContainer: {
    display: "inline",
    float: "right",
  },
  leftColumn: {
    flex: "30%",
  },
  rightColumn: {
    flex: "70%",
  },
  right: {
    marginLeft: "auto",
  },
  inline: {
    display: "flex",
  },
});

interface WorkItemTableProps {
  rows: IWorkItem[];
  selected: IWorkItem[];
  columnsToHide?: string[];
  start: DateTime;
  end: DateTime;
  showInvoicedWorkItems: boolean;
  timeRanges: Map<string, IDateRange>;
  tableContainerProps?: CSSProperties;
  therapist?: ITherapist;
  listStudents?: boolean;
  showNewButton?: boolean;
  showTotals?: boolean;
  altToolbar?: JSX.Element;
  altFooter?: JSX.Element;
  setSelected: (selected: IWorkItem[]) => void;
  setStartDate: (start: DateTime | null) => void;
  setEndDate: (end: DateTime | null) => void;
  setShowInvoicedWorkItems: (show: boolean) => void;
  onDelete?: (item: IWorkItem) => void;
}

const logger = createLogger("WorkItemTable");

export default function WorkItemTable(props: WorkItemTableProps): JSX.Element {
  const { rows, columnsToHide, therapist, showNewButton, listStudents } = props;

  const { schools } = React.useContext(SchoolContext);
  const { therapists } = React.useContext(TherapistContext);
  const workItemContext = React.useContext(WorkItemContext);
  const api = React.useContext(API);
  const [showEditWorkItem, setShowEditWorkItem] =
    React.useState<boolean>(false);
  const [showWorkItemForm, setShowWorkItemForm] =
    React.useState<boolean>(false);
  const [editingWorkItem, setEditingWorkItem] = React.useState<
    IWorkItem | undefined
  >(undefined);
  const [workItemToDelete, setWorkItemToDelete] = React.useState<
    IWorkItem | undefined
  >(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState<boolean>(false);

  const { classes } = React.useContext(SettingsContext);

  const onStartWorkItemEdit = React.useCallback((item: IWorkItem) => {
    setEditingWorkItem(item);
    setShowEditWorkItem(true);
  }, []);

  const onStopWorkItemEdit = React.useCallback(() => {
    setEditingWorkItem(undefined);
    setShowEditWorkItem(false);
  }, [setEditingWorkItem]);

  const onStartWorkItemDelete = React.useCallback((item: IWorkItem) => {
    setWorkItemToDelete(item);
    setShowDeleteConfirmation(true);
  }, []);

  const deleteWorkItem = React.useCallback(() => {
    if (!workItemToDelete) return;
    api
      .deleteWorkItems([workItemToDelete._id])
      .then(async (res) => {
        if (isContentJSON(res)) {
          await res.json().then((json) => {
            if (res.status === 200) {
              Toaster("Work Item Deleted", ToastType.good);
              workItemContext.makeDirty();
            } else {
              Toaster(
                `Could not delete Work Item: ${json.message}`,
                ToastType.error
              );
              logger.error(
                `ERROR ${res.status} => ${res.statusText}: ${json.message}`,
                res
              );
            }
          });
        } else if (isContentText(res)) {
          await res.text().then((text) => {
            if (res.status === 200) {
              // Should never happen
            } else {
              Toaster(text, ToastType.error);
              logger.error(`ERROR ${res.status} => ${res.statusText}: ${text}`);
            }
          });
        } else if (isContentHTML(res)) {
          await res.text().then((html) => {
            if (res.status === 200) {
              // Should never happen
            } else {
              if (html.localeCompare("Insufficient scope") === 0) {
                Toaster(
                  `Error: ${html}.
                    You do not have sufficient permissions to perform that operation.`,
                  ToastType.error
                );
                logger.error(
                  `ERROR ${res.status} => ${res.statusText}: ${html}`,
                  res
                );
              } else {
                Toaster(`Error: ${html}`, ToastType.error);
                logger.error(
                  `ERROR ${res.status} => ${res.statusText}: ${html}`,
                  res
                );
              }
            }
          });
        } else {
          Toaster("Unknown Error Occurred", ToastType.error);
          logger.error(`ERROR ${res.status} => ${res.statusText}:`, res);
        }
      })
      .catch((e) => logger.error(e));
    setShowDeleteConfirmation(false);
  }, [api, workItemContext, workItemToDelete]);

  const styles = useStyles();

  const script: OverrideScript<IWorkItem> = React.useMemo(
    () => [
      {
        id: "school",
        text: "School",
        getField: (member: IWorkItem) =>
          schools.find((s) => s.id === member.schoolId)?.name ?? "Unknown",
      },
      {
        id: "dateWorked",
        text: "Date Worked",
        getField: (member: IWorkItem) =>
          DateTime.fromISO(member.dateWorked).toFormat("LLL dd, yyyy"),
      },
      {
        id: "dateLogged",
        text: "Date Logged",
        getField: (member: IWorkItem) =>
          DateTime.fromISO(member.dateLogged).toFormat("LLL dd, yyyy"),
      },
      {
        id: "service",
        text: "Service",
        getField: (member: IWorkItem) => {
          if (member.classId === "" || !member.classId) return "";

          return classes[member.classId].name;
        },
      },
      {
        id: "workType",
        text: "Work Type",
        getField: (member: IWorkItem) => {
          if (
            !member.classId ||
            !member.workTypeId ||
            member.classId === "" ||
            member.workTypeId === ""
          )
            return "";

          return classes[member.classId].workTypes[member.workTypeId].name;
        },
      },
      {
        id: "units",
        text: "Units",
        getField: (member: IWorkItem) => member.units,
      },
      {
        id: "students",
        text: "Students",
        getField: (member: IWorkItem) =>
          listStudents
            ? member.students.map((std) => std.name).join(", ")
            : member.students.length,
      },
      {
        id: "therapist",
        text: "Therapist",
        getField: (member: IWorkItem) =>
          therapists.find((therp) => therp.userId === member.therapistUserId)
            ?.name ?? "Unknown",
      },
      {
        id: "invoiceId",
        text: "Invoice ID",
        getField: (member: IWorkItem) => member.invoiceId,
      },
      {
        isAction: true,
        id: "action",
        text: "Action",
        align: "right",
        action: [onStartWorkItemEdit, onStartWorkItemDelete],
        actionIcon: [<EditIcon key={"edit"} />, <DeleteIcon key={"delete"} />],
        setDisabled: (member: IWorkItem) =>
          !(!member.invoiceId || member.invoiceId === ""),
      },
    ],
    [
      classes,
      listStudents,
      onStartWorkItemDelete,
      onStartWorkItemEdit,
      schools,
      therapists,
    ]
  );

  const filteredScript = React.useMemo(
    () =>
      script.filter(
        (verse: OverrideVerse<IWorkItem>) =>
          !columnsToHide?.includes(verse.id) ?? true
      ),
    [script, columnsToHide]
  );

  const handleNewWorkItem = React.useCallback(() => {
    setShowWorkItemForm(true);
  }, []);

  const tableToolbar = React.useMemo(() => {
    if (props.altToolbar) return props.altToolbar;
    const totalUnitHours =
      rows.map((r) => r.units).reduce((a, u) => a + u, 0) / 4;
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div className={clsx(styles.leftColumn, styles.inline)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto 0",
            }}
          >
            <Typography
              style={{ textAlign: "center", padding: "10px" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {"Work Items"}
            </Typography>
          </div>
          <div style={{ height: "90%", marginTop: "auto" }}>
            <BoldNumberDisplay
              label={"Units"}
              num={totalUnitHours}
              unit={"Hours"}
              variant={"h4"}
              compact
            />
          </div>
        </div>
        <div
          style={{ display: "flex", paddingLeft: "auto" }}
          className={clsx(styles.rightColumn, styles.right)}
        >
          <div style={{ height: "90%", marginLeft: "auto" }}>
            <RangeDatePicker
              start={props.start}
              end={props.end}
              predefinedOptions={Array.from(props.timeRanges.values())}
              setStart={props.setStartDate}
              setEnd={props.setEndDate}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Eye
              visible={props.showInvoicedWorkItems}
              toggle={() =>
                props.setShowInvoicedWorkItems(!props.showInvoicedWorkItems)
              }
            />
          </div>
        </div>
      </div>
    );
  }, [
    props,
    rows,
    styles.inline,
    styles.leftColumn,
    styles.right,
    styles.rightColumn,
  ]);

  return (
    <Paper style={{ width: "100%", height: "100%" }}>
      <SortableTable
        label={"Work Items"}
        script={filteredScript}
        rows={rows}
        selectionFilters={[]}
        tableContainerProps={props.tableContainerProps}
        noContentText={"No records found for selected date range"}
        altToolbar={tableToolbar}
        defaultSortKey="dateWorked"
        defaultSortOrder="desc"
        hidePagination
      />
      {showNewButton && (
        <div className={styles.fabContainer}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add-new-work-time"
            onClick={handleNewWorkItem}
            className={styles.fabMargin}
          >
            <AssignmentTurnedInOutlinedIcon />
            <Typography variant="subtitle2">New Work Item</Typography>
          </Fab>
        </div>
      )}
      <SingleWorkItemEditor
        open={showEditWorkItem}
        editingWorkItem={editingWorkItem}
        schools={schools}
        setOpen={onStopWorkItemEdit}
      />
      {therapist && (
        <WorkItemFormModal
          open={showWorkItemForm}
          setOpen={setShowWorkItemForm}
          therapist={therapist}
        />
      )}
      <ConfirmationModal
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningRoundedIcon
              fontSize="large"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center">
              This operation is <b>irreversible!</b> Are you sure you want to
              delete this work item?
            </Typography>
          </div>
        }
        confirmText={"No, don't delete"}
        cancelText={"Yes, delete item"}
        onConfirm={() => {
          setWorkItemToDelete(undefined);
          setShowDeleteConfirmation(false);
        }}
        onCancel={() => deleteWorkItem()}
        open={showDeleteConfirmation}
        setOpen={(show: boolean) => setShowDeleteConfirmation(show)}
      />
    </Paper>
  );
}
