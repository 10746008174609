import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { ITherapist } from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { getContentType, isContentJSON, isContentText } from "../common/Utils";
import { ErrorPage } from "../components/Display/ErrorPage";
import { createLogger } from "../components/Logging/Logging";
import { API } from "./APIContext";
import Toaster, { ToastType } from "../common/Toaster";

interface IUserContext {
  role: string;
  isAdmin: boolean;
  id: string;
  therapist: ITherapist | undefined;
  isLoading: boolean;
}

interface IUserContextProps {}

const defaultState: IUserContext = {
  role: "",
  isAdmin: false,
  id: "",
  therapist: undefined,
  isLoading: true,
};

export const UserContext = React.createContext<IUserContext>(defaultState);

const logger = createLogger("UserContext");

export default function UserContextProvider(
  props: React.PropsWithChildren<IUserContextProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = React.useContext(API);

  const [state, setState] = React.useState<IUserContext>(defaultState);
  const { getAccessTokenSilently } = useAuth0();
  const { user } = React.useContext(Auth0Context);
  const [error, setError] = React.useState<{
    error: string | undefined;
    code: number;
  }>({
    error: undefined,
    code: 0,
  });
  React.useEffect(() => {
    if (user === undefined) {
      return;
    }
    const roles: string[] = user["https://rsp/roles"];
    if (roles === undefined || roles.length === 0 || user.sub === undefined) {
      setError({
        error:
          "Auth0 account for this user is malformed. " +
          "Invalid roles, please contact your administrator",
        code: 1,
      });
      return;
    }
    const primaryRole = roles[0];
    const isAdmin = primaryRole === "Admin";
    const id = user.sub.replace("auth0|", "");

    getAccessTokenSilently({ timeoutInSeconds: 10 }).then((res) => {
      document.cookie = `token=${res}`;
      if (document.cookie === undefined) {
        window.location.reload();
      }
    });
    if (isMounted.current) {
      setState((prev) => ({
        ...prev,
        role: primaryRole,
        isAdmin,
        id,
        therapist: undefined,
      }));
      if (!isAdmin) {
        api
          .getTherapistByID({ userId: id })
          .then(async (res) => {
            if (res.ok) {
              if (isContentJSON(res)) {
                await res
                  .json()
                  .then((j) => {
                    if (res.status === 200) {
                      setState((prev) => ({
                        ...prev,
                        therapist: j as ITherapist,
                        isLoading: false,
                      }));
                    } else {
                      setError({
                        error: res.statusText,
                        code: res.status,
                      });
                      logger.error(
                        `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                        {
                          res,
                          j,
                        }
                      );
                    }
                  })
                  .catch((e) => {
                    logger.error(e, res);
                    throw Error(e);
                  });
              } else if (isContentText(res)) {
                res.text().then((t) => {
                  console.warn(`Got text back from /retrieve-user-info: ${t}`);
                  // Toaster(
                  //   "Warning: got unexpected text response from /retrieve-user-info",
                  //   ToastType.info
                  // );
                });
              } else {
                console.log(res);
                Toaster(
                  `Got unexpected return type from /retrieve-user-info: ${getContentType(
                    res
                  )}`,
                  ToastType.info
                );
              }
            } else {
              switch (res.status) {
                case 404:
                  const message =
                    "User is valid in Auth0 but had not been added to RSP, " +
                    "contact your administrator.";
                  logger.error(message, { res, user });
                  Toaster(message, ToastType.error);
                  setError({
                    error: message,
                    code: 404,
                  });
              }
            }
          })
          .catch((e) => {
            logger.error(e);
            throw Error(e);
          });
      }
    }
  }, [user, getAccessTokenSilently, isMounted, api]);

  const children = React.useMemo(() => {
    if (error.error) {
      if (error.code === 404) {
        const message = `We couldn't find this user in the database: ${
          user?.email ?? ""
        }. 
                         Most likely your account is valid in Auth0 ID but is not
                         registered in RSP.
                         Please contact your administrator about getting added to RSP.`;
        return <ErrorPage errorMessage={message} code={404} />;
      } else if (error.code === 401) {
        return <ErrorPage errorMessage={"Hello"} code={401} />;
      } else if (error.code === 0) {
        const message = `The credentials used to login are valid in Auth0 but misconfigured
                         elsewhere. Please contact your administrator.`;
        return <ErrorPage errorMessage={message} code={0} />;
      } else {
        return (
          <ErrorPage errorMessage={`ERROR ${error.code}: ${error.error}`} />
        );
      }
    }
    return props.children;
  }, [error.code, error.error, props.children, user?.email]);

  React.useEffect(() => {
    logger.info(`loaded = ${!state.isLoading}`);
  }, [state.isLoading]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
