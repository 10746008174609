import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  name: {
    fontFamily: 'Roboto',
  },
  img: {
    height: '40%',
    width: '40%',
    borderRadius: '100px',
    boxShadow: '0 0 4px black',
  },
  imgContainer: {
    padding: 'auto',
    display: 'block',
    margin: 'auto',
    marginRight: '0px',
  },
  container: {
    display: 'flex',
    alignContent: 'center',
  },
});

export default function UserInfoComponent(): JSX.Element {
  const styles = useStyles();
  const { user } = useAuth0();

  if (user === undefined) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Tooltip title={user.name ?? ''} arrow>
          <img src={user.picture} alt="Profile" className={styles.img} />
        </Tooltip>
      </div>
    </div>
  );
}
