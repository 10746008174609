import { OverrideScript } from "../../../common/Script";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): 1 | -1 | 0 {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key,
  script: OverrideScript<T>
): (a: T, b: T) => number {
  const column = script.find((verse) => verse.id === orderBy);
  const comp = column?.comparator ?? descendingComparator;
  return order === 'desc' ?
    (a, b) => comp(a, b, orderBy) :
    (a, b) => -comp(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}