import {
  Box,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  TextField,
} from "@material-ui/core";
import React from "react";
import { clsx } from "../../clsx";
import {
  DropdownSelectorOption,
  IGrade,
  IWorkType,
} from "../../common/interfaces";
import {
  AlternateID,
  Action,
  WithIndex,
  AlternateFactory,
  OverrideScript,
} from "../../common/Script";
import Toaster, { ToastType } from "../../common/Toaster";
import {
  AddIcon,
  CancelOutlinedIcon,
  DeleteIcon,
  EditIcon,
  SaveOutlinedIcon,
} from "../../icons";
import {
  makeAlternate,
  SimpleActionTable,
} from "../Display/Table/SimpleActionTable";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";

export interface NewWorkTypeInputProps extends Partial<HTMLElement> {
  types: { [uuid: string]: IWorkType };
  grades: { [uuid: string]: IGrade };
  addType: (type: IWorkType) => void;
  updateType: (id: string, updates: IWorkType) => void;
  removeType: (id: string) => void;
}

const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    padding: "5px",
    // justifyContent: "space-evenly",
    flexDirection: "row",
  },
  textFieldColumn: {
    flex: "40%",
    marginTop: "5px",
    marginLeft: "5px",
  },
  gradeDropdownColumn: {
    flex: "20%",
    // marginTop: "10px",
  },
  requireSwitchColumn: {
    flex: "10%",
    // margin: "auto",
  },
  submitButtonColumn: {
    flex: "5%",
  },
  submitButton: {
    margin: "12px 0px 0px 0px",
  },
  noDisplay: {
    display: "none",
  },
});

export function NewWorkTypeInput(props: NewWorkTypeInputProps): JSX.Element {
  const { types, grades, addType, updateType, removeType } = props;
  const styles = useStyles();

  const [newTypeName, setNewTypeName] = React.useState<string>("");
  const [newTypeGradeId, setNewTypeGrade] = React.useState<string>("");
  const [newTypeRequireStudents, setNewTypeRequireStudents] =
    React.useState<boolean>(false);

  const resetNewType = () => {
    setNewTypeGrade("");
    setNewTypeName("");
    setNewTypeRequireStudents(false);
  };
  const [currentAlternate, setCurrentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });
  const [error, setError] = React.useState<boolean>(false);

  const add = React.useCallback(() => {
    if (newTypeName.length === 0) {
      setError(true);
      return;
    }

    // NOTE: This isn't necessary from a technical perspective since we're storing work types by
    // UUID, although it's probably a good idea to keep it here from a UX perspective.
    if (
      Object.values(types)
        .map((t) => t.name)
        .includes(newTypeName)
    ) {
      Toaster(`Type '${newTypeName}' cannot be duplicated.`, ToastType.error);
      setError(true);
      return;
    }

    addType({
      name: newTypeName,
      grade: newTypeGradeId,
      requireStudents: newTypeRequireStudents,
    });
    resetNewType();
  }, [addType, newTypeGradeId, newTypeName, newTypeRequireStudents, types]);

  const onNameInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setError(false);
      setNewTypeName(event.target.value);
    },
    [setNewTypeName]
  );

  const onGradeInputChange = React.useCallback(
    (option: DropdownSelectorOption) => {
      setError(false);
      setNewTypeGrade(option.value);
    },
    [setNewTypeGrade]
  );

  const startEdit = React.useCallback<Action<{ id: string; type: IWorkType }>>(
    (typeWithIndex: WithIndex<{ id: string; type: IWorkType }>) => {
      setCurrentAlternate(makeAlternate("edit", typeWithIndex.index));
      setNewTypeGrade(typeWithIndex.type.grade);
      setNewTypeName(typeWithIndex.type.name);
      setNewTypeRequireStudents(typeWithIndex.type.requireStudents);
    },
    [setNewTypeGrade, setNewTypeName, setNewTypeRequireStudents]
  );

  const stopEdit = React.useCallback(() => {
    setCurrentAlternate(makeAlternate("", -1));
    resetNewType();
  }, []);

  const sortedWorkTypes = React.useMemo(() => {
    return Object.entries(types)
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, type]) => ({ id, type }));
  }, [types]);

  const sortedGrades = React.useMemo(() => {
    return Object.entries(grades).sort(([, a], [, b]) =>
      a.name.localeCompare(b.name)
    );
  }, [grades]);

  const sortedGradeOptions = React.useMemo(() => {
    return makeDropdownOptions(
      sortedGrades.map(([id, grade]) => ({ id, name: grade.name })),
      "name",
      "id"
    );
  }, [sortedGrades]);

  const getTypeNameEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <TextField
        className={clsx()}
        variant="outlined"
        value={newTypeName}
        onChange={onNameInputChange}
      />
    );
  }, [onNameInputChange, newTypeName]);

  const getTypeGradeEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <DropdownSelect
        className={clsx()}
        value={{
          label: newTypeGradeId in grades ? grades[newTypeGradeId].name : "",
          value: newTypeGradeId,
        }}
        handleChange={(ns) => {
          if (ns) onGradeInputChange(ns);
        }}
        options={sortedGradeOptions}
      />
    );
  }, [grades, newTypeGradeId, onGradeInputChange, sortedGradeOptions]);

  const getTypeRequireStudentsEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={newTypeRequireStudents}
            onChange={() => setNewTypeRequireStudents(!newTypeRequireStudents)}
            name="checkedB"
            color="primary"
          />
        }
        label={newTypeRequireStudents ? "Yes" : "No"}
      />
    );
  }, [setNewTypeRequireStudents, newTypeRequireStudents]);

  const save = React.useCallback(
    (id: string) => {
      if (error) return;
      updateType(id, {
        name: newTypeName,
        grade: newTypeGradeId,
        requireStudents: newTypeRequireStudents,
      });
      stopEdit();
    },
    [
      newTypeGradeId,
      newTypeName,
      newTypeRequireStudents,
      error,
      updateType,
      stopEdit,
    ]
  );
  const getActionEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(
    ({ id }) => {
      return (
        <>
          <IconButton onClick={() => save(id)}>
            <SaveOutlinedIcon />
          </IconButton>
          <IconButton onClick={stopEdit}>
            <CancelOutlinedIcon />
          </IconButton>
        </>
      );
    },
    [save, stopEdit]
  );

  const overrideScript: OverrideScript<{ id: string; type: IWorkType }> = [
    {
      id: "name",
      text: "Type Name",
      getField: ({ type }) => type.name,
      alternates: {
        edit: getTypeNameEditComponent,
      },
    },
    {
      id: "grade",
      text: "Grade",
      getField: ({ type }) =>
        type.grade in grades ? grades[type.grade].name : "",
      alternates: {
        edit: getTypeGradeEditComponent,
      },
    },
    {
      id: "requireStudents",
      text: "Require Students",
      align: "right",
      getField: ({ type }) => (type.requireStudents ? "Yes" : "No"),
      alternates: {
        edit: getTypeRequireStudentsEditComponent,
      },
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [startEdit, ({ id }) => removeType(id)],
      actionIcon: [<EditIcon key="edit" />, <DeleteIcon key="delete" />],
      alternates: {
        edit: getActionEditComponent,
      },
    },
  ];

  const showInputs = currentAlternate.alternate !== "edit";

  return (
    <Paper className={props.className}>
      <SimpleActionTable
        population={sortedWorkTypes}
        title={"Work Types"}
        action={({ id, type }) => {
          if (!type) return;
          removeType(id);
        }}
        actionIcon={<DeleteIcon />}
        makeDisplay={({ type }) => type.name}
        overrideScript={overrideScript}
        currentAlternate={currentAlternate}
      />
      {showInputs && (
        <Box className={clsx(styles.inputContainer)}>
          <TextField
            className={clsx(styles.textFieldColumn)}
            variant="outlined"
            label="Type Name"
            value={newTypeName}
            onChange={onNameInputChange}
            error={error}
          />
          <DropdownSelect
            className={clsx(styles.gradeDropdownColumn)}
            value={{
              label:
                newTypeGradeId in grades ? grades[newTypeGradeId].name : "",
              value: newTypeGradeId,
            }}
            handleChange={(ns) => {
              if (ns) onGradeInputChange(ns);
            }}
            label={"Grade"}
            isSearchable={false}
            options={sortedGradeOptions}
          />
          <FormControlLabel
            className={styles.requireSwitchColumn}
            control={
              <Switch
                checked={newTypeRequireStudents}
                onChange={() =>
                  setNewTypeRequireStudents(!newTypeRequireStudents)
                }
                name="checkedB"
                color="primary"
              />
            }
            label={"Require Students"}
          />
          <div className={clsx(styles.submitButtonColumn)}>
            <IconButton
              className={styles.submitButton}
              color="primary"
              onClick={() => {
                add();
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>
      )}
    </Paper>
  );
}
