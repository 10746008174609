import { Grid } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";

interface BasicViewProps {}

function BasicView(props: React.PropsWithChildren<BasicViewProps>) {
  return (
    <Grid container spacing={3}>
      {props.children}
    </Grid>
  );
}

export default withRouter(BasicView);
