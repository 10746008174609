import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { clsx } from 'clsx';
import { AccountCircleIcon } from '../../icons';
import { ConfirmDenyButtonAction } from '../Input/ConfirmDenyButtonAction';

export interface IndividualStaffViewHeaderProps {
  title: string;
  cancel: () => void;
  save: () => void;
}

const useStyles = makeStyles({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    alignSelf: 'center',
    padding: '5px',
  },
  saveCancelButton: {
    width: '40%',
    padding: '10px',
    marginBottom: '10px',
    marginLeft: 'auto'
  }, 
  profile: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
  },
  icon: {
    maring: 'auto',
    padding: '5px'
  }
});



export function IndividualStaffViewHeader(props: IndividualStaffViewHeaderProps): JSX.Element {
  const { save, cancel } = props;
  const styles = useStyles();
  return (
    <div className={clsx(styles.flexRow)}>
      <div className={styles.profile}>
        <div className={styles.icon}>
          <AccountCircleIcon 
            fontSize={'large'}
          />
        </div>
        <Typography variant="h4" className={styles.name}>
          {props.title}
        </Typography>
      </div>
      <div className={clsx(styles.saveCancelButton)}>
        <ConfirmDenyButtonAction 
          confirmAction={save}
          confirmText={'Save'}
          denyAction={cancel}
          denyText={'Cancel'}
        />
      </div>
    </div>
  );
}