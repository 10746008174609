import { Toolbar, Typography, IconButton, Tooltip } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React from "react";
import { clsx } from "../../../clsx";
import { DeleteIcon } from "../../../icons";

export interface SortableTableToolbarProps {
  label: string;
  numSelected?: number;
  showDelete?: boolean;
  altToolbar?: JSX.Element;
  onDelete?: (() => void) | undefined;
  useStyles?: (props?: unknown) => ClassNameMap<string>;
}

export function SortableTableToolbar(
  props: SortableTableToolbarProps
): JSX.Element {
  const { label, numSelected, onDelete, showDelete, useStyles } = props;
  const classes = useStyles?.() ?? {};

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected && numSelected > 0,
      })}
    >
      {numSelected && numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : props.altToolbar !== undefined ? (
        props.altToolbar
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {label}
        </Typography>
      )}
      {showDelete && numSelected && numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}
