import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { clsx } from '../../clsx';

export interface ConfirmDenyButtonActionProps {
  confirmText?: string;
  denyText?: string;
  confirmAction: () => void;
  denyAction: () => void;
}

const useStyles = makeStyles({
  buttonContainer: {
    paddingTop: '10px'
  },
  confirmColumn: {
    width: '40%'
  },
  denyColumn: {
    width: '40%'
  },
  confirmButton: {
  },
  denyButton: {
  
  }
});

export function ConfirmDenyButtonAction(props: ConfirmDenyButtonActionProps): JSX.Element {
  const {
    confirmText,
    denyText,
    confirmAction,
    denyAction
  } = props;
  const styles = useStyles();
  return (
    <Grid 
      container
      justifyContent='space-evenly'
      spacing={1}
      className={clsx(styles.buttonContainer)}
    >
      <Grid item className={styles.denyColumn}>
        <Button
          className={clsx(styles.denyButton)}
          variant="outlined"
          color="secondary"
          onClick={denyAction}
          fullWidth
        >
          {denyText ?? 'Deny'}
        </Button>
      </Grid>
      <Grid item className={styles.confirmColumn}>
        <Button
          className={clsx(styles.confirmButton)}
          variant="contained"
          color="primary"
          onClick={confirmAction}
          fullWidth
        >
          {confirmText ?? 'Confirm'}
        </Button>
      </Grid>
    </Grid>
  );
}