import { TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import PopoverDropdown from "../PopoverDropdown";
import { SimpleActionTable } from "../Display/Table/SimpleActionTable";
import { IStudent } from "../../common/interfaces";
const useStyles = makeStyles({
  table: {
    padding: "0",
  },
  label: {
    textAlign: "center",
    fontSize: "12",
    width: "100%",
    margin: "auto",
  },
  error: {
    border: "1px red solid",
  },
});

interface PredefinedStudentSelectorProps {
  options: IStudent[];
  selected: IStudent[];
  addStudent: (input: IStudent) => void;
  removeStudent: (input: IStudent) => void;
  tableClassName?: string;
  paperClassName?: string;
  hideBorder?: boolean;
  error?: boolean;
}

export default function PredefinedStudentSelector(
  props: PredefinedStudentSelectorProps
): JSX.Element {
  const {
    selected,
    addStudent,
    removeStudent,
    paperClassName,
    options,
    hideBorder,
    error,
  } = props;
  const classes = useStyles();

  const sortedOptions = React.useMemo(
    () => options.sort((a, b) => a.name.localeCompare(b.name)),
    [options]
  );

  const notSelected = sortedOptions.filter((v) => !selected.includes(v));

  return (
    <TableContainer
      component={Paper}
      className={
        hideBorder ? "" : clsx(paperClassName, error ? classes.error : "")
      }
      elevation={hideBorder ? 1 : 3}
    >
      <SimpleActionTable
        title={"Students"}
        population={selected}
        action={removeStudent}
        actionIcon={<DeleteIcon />}
        makeDisplay={(input: IStudent) => input.name}
      />
      <PopoverDropdown
        listValues={notSelected}
        select={addStudent}
        makeDisplay={(input: IStudent) => input.name}
      />
    </TableContainer>
  );
}
