import React from "react";
import { UserContext } from "./context/UserContext";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import InvoiceContextProvider from "./context/InvoiceContext";
import TherapistContextProvider from "./context/TherapistContext";
import UserDashboard from "./components/UserDashboard/UserDashboard";

export default function App(): JSX.Element {
  const { isAdmin } = React.useContext(UserContext);
  if (isAdmin) {
    return (
      <TherapistContextProvider>
        <InvoiceContextProvider>
          <AdminDashboard />
        </InvoiceContextProvider>
      </TherapistContextProvider>
    );
  } else {
    return <UserDashboard />;
  }
}
