import {
  Paper,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ChangeEvent } from "react";
import { clsx } from "../../clsx";
import { IGrade } from "../../common/interfaces";
import {
  Action,
  AlternateFactory,
  AlternateID,
  OverrideVerse,
  WithIndex,
} from "../../common/Script";
import Toaster, { ToastType } from "../../common/Toaster";
import {
  DeleteIcon,
  AddIcon,
  EditIcon,
  SaveOutlinedIcon,
  CancelOutlinedIcon,
} from "../../icons";
import {
  SimpleActionTable,
  makeAlternate,
} from "../Display/Table/SimpleActionTable";

export interface NewGradeInputProps extends Partial<HTMLElement> {
  grades: { [uuid: string]: IGrade };
  addGrade: (grade: IGrade) => void;
  removeGrade: (name: string) => void;
  editGrade: (id: string, grade: IGrade) => void;
}

const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  textFieldColumn: {
    flex: "55%",
    margin: "10px",
  },
  gradeDropdownColumn: {
    flex: "40%",
    marginTop: "10px",
  },
  submitButtonColumn: {
    flex: "5%",
  },
  submitButton: {
    margin: "12px 0px 0px 0px",
  },
});

export function NewGradeInput(props: NewGradeInputProps): JSX.Element {
  const { grades, addGrade, removeGrade, editGrade } = props;
  const styles = useStyles();
  const [newGradeName, setNewGradeName] = React.useState<string>("");
  const [newGradeRate, setNewGradeRate] = React.useState<number>(0);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [rateError, setRateError] = React.useState<boolean>(false);
  const [currentAlternate, setCurrentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });
  const [editingGradeUUID, setEditingGradeUUID] = React.useState<
    string | undefined
  >(undefined);
  const [editingRate, setEditingRate] = React.useState<number>(
    editingGradeUUID ? grades[editingGradeUUID].rate : 0
  );

  const add = React.useCallback(() => {
    if (newGradeName.length === 0) {
      setNameError(true);
      return;
    }
    if (newGradeRate === 0) {
      setRateError(true);
      return;
    }

    // NOTE: This isn't necessary from a technical perspective since we're storing grades by UUID,
    // although it's probably a good idea to keep it here from a UX perspective.
    if (
      Object.values(grades)
        .map((grade) => grade.name)
        .includes(newGradeName)
    ) {
      Toaster(`Grade ${newGradeName} cannot be duplicated`, ToastType.error);
      setNameError(true);
      return;
    }

    addGrade({ name: newGradeName, rate: newGradeRate });
    setNewGradeName("");
    setNewGradeRate(0);
  }, [newGradeName, newGradeRate, grades, addGrade]);

  const startEdit = React.useCallback<Action<{ id: string; grade: IGrade }>>(
    (typeWithIndex: WithIndex<{ id: string; grade: IGrade }>) => {
      setCurrentAlternate(makeAlternate("edit", typeWithIndex.index));
      setEditingGradeUUID(typeWithIndex.id);
      setEditingRate(typeWithIndex.grade.rate);
    },
    []
  );
  const stopEdit = React.useCallback(() => {
    setCurrentAlternate(makeAlternate("", -1));
    setEditingRate(0);
    setEditingGradeUUID(undefined);
  }, []);

  const edit = React.useCallback(() => {
    if (editingRate === 0) {
      setRateError(true);
      return;
    }

    if (editingGradeUUID === undefined) {
      // Should never happen
      return;
    }

    editGrade(editingGradeUUID, {
      name: grades[editingGradeUUID].name,
      rate: editingRate,
    });

    stopEdit();
  }, [editGrade, editingGradeUUID, editingRate, grades, stopEdit]);

  const getActionEditComponent = React.useCallback<
    AlternateFactory<{ id: string; grade: IGrade }>
  >(() => {
    return (
      <>
        <IconButton onClick={edit}>
          <SaveOutlinedIcon />
        </IconButton>
        <IconButton onClick={stopEdit}>
          <CancelOutlinedIcon />
        </IconButton>
      </>
    );
  }, [edit, stopEdit]);

  const removeGradeAction = React.useCallback(
    ({ id, grade }) => {
      if (!grade) return;
      removeGrade(id);
    },
    [removeGrade]
  );

  const overrideCells: OverrideVerse<{ id: string; grade: IGrade }>[] = [
    {
      id: "name",
      text: "Grade Name",
      getField: ({ grade }) => grade.name,
    },
    {
      id: "rate",
      text: "Rate (USD/unit)",
      getField: ({ grade }) => `$${grade.rate}`,
      alternates: {
        edit: () => (
          <TextField
            className={clsx(styles.gradeDropdownColumn)}
            variant="outlined"
            type="number"
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setEditingRate(parseFloat(e.target.value));
            }}
            value={editingRate}
          />
        ),
      },
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [startEdit, removeGradeAction],
      actionIcon: [<EditIcon key="edit" />, <DeleteIcon key="delete" />],
      alternates: {
        edit: getActionEditComponent,
      },
    },
  ];

  return (
    <Paper className={props.className}>
      <SimpleActionTable
        population={Object.entries(grades).map(([id, grade]) => ({
          id,
          grade,
        }))}
        title={"Grade"}
        action={removeGradeAction}
        actionIcon={<DeleteIcon />}
        makeDisplay={({ grade }) => grade.name}
        overrideScript={overrideCells}
        currentAlternate={currentAlternate}
      />
      <Box className={clsx(styles.inputContainer)}>
        <TextField
          className={clsx(styles.textFieldColumn)}
          variant="outlined"
          label="Grade Name"
          value={newGradeName}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setNameError(false);
            setNewGradeName(e.target.value);
          }}
          error={nameError}
        />
        <TextField
          className={clsx(styles.gradeDropdownColumn)}
          variant="outlined"
          label="New Rate"
          type="number"
          inputProps={{
            min: 0,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setRateError(false);
            setNewGradeRate(parseFloat(e.target.value));
          }}
          value={newGradeRate}
          error={rateError}
        />
        <div className={clsx(styles.submitButtonColumn)}>
          <IconButton
            className={styles.submitButton}
            color="primary"
            onClick={() => {
              add();
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </Box>
    </Paper>
  );
}
