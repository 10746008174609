import React from "react";
import { formatDateString } from "../../common/DateUtils";
import {
  IEditByID,
  ISchool,
  IWorkItem,
  IWorkItemContext,
  ModalProps,
} from "../../common/interfaces";
import Toaster, { ToastType } from "../../common/Toaster";
import { API } from "../../context/APIContext";
import { WorkItemContext } from "../../context/WorkItemContext";
import { DefaultModal } from "../Display/DefaultModal";
import { SingleWorkItemEditorControls } from "./SingleWorkItemEditorControls";
import { DateTime } from "luxon";

export interface SingleWorkItemEditorProps extends ModalProps {
  editingWorkItem: IWorkItem | undefined;
  schools: ISchool[];
}

export function SingleWorkItemEditor(
  props: SingleWorkItemEditorProps
): JSX.Element {
  const { editingWorkItem, open, schools, setOpen } = props;
  const api = React.useContext(API);
  const { makeDirty } = React.useContext<IWorkItemContext>(WorkItemContext);

  const handleSaveWorkItem = React.useCallback(
    (workItem: IWorkItem) => {
      const payload: IEditByID = {
        id: workItem._id,
        updateObj: {
          schoolId: workItem.schoolId,
          dateWorked: formatDateString(
            DateTime.fromISO(workItem.dateWorked).toISO() ?? ""
          ),
          workTypeId: workItem.workTypeId,
          classId: workItem.classId,
          units: Number(workItem.units),
          students: workItem.students,
        },
      };

      api.editWorkItem(payload).then(
        async (res) =>
          await res.json().then((json) => {
            if (res.status === 200) {
              Toaster("Work Item updated", ToastType.good);
              makeDirty();
            } else {
              Toaster(
                `Failed to update Work Item ${json.message}`,
                ToastType.error
              );
            }
          })
      );
      setOpen(false);
    },
    [api, makeDirty, setOpen]
  );

  const close = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (editingWorkItem === undefined) return <></>;
  return (
    <DefaultModal onClose={close} open={open}>
      <SingleWorkItemEditorControls
        editingWorkItem={editingWorkItem}
        schools={schools}
        saveEdit={handleSaveWorkItem}
        cancelEdit={close}
      />
    </DefaultModal>
  );
}
