import { makeStyles } from "@material-ui/styles";
import React from "react";
import { clsx } from "../../clsx";
import {
  DropdownSelectorOption,
  ISchool,
  IStudent,
  IWorkItem,
} from "../../common/interfaces";
import { copy } from "../../common/Utils";
import { TherapistContext } from "../../context/TherapistContext";
import { ConfirmDenyButtonAction } from "./ConfirmDenyButtonAction";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import PredefinedStudentSelector from "./PredefinedStudentSelector";
import { SimpleDatePicker } from "./SimpleDatePicker";
import { UnitSelector } from "./UnitSelector";
import { DateTime } from "luxon";
import { SettingsContext } from "../../context/SettingsContext";
import { Paper, Typography, Box } from "@material-ui/core";

export interface SingleWorkItemEditorControlsProps {
  editingWorkItem: IWorkItem;
  schools: ISchool[];
  saveEdit: (editedWorkItem: IWorkItem) => void;
  cancelEdit: (changed: boolean) => void;
}

const useStyles = makeStyles({
  input: {
    width: "90%",
    margin: "5px auto",
    minWidth: "10%",
  },
  editorContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    overflowX: "scroll",
  },
  paper: {
    padding: "10px",
    minWidth: "400px",
    marginBottom: "10px",
  },
  title: {
    flexGrow: 1,
  },
});

export function SingleWorkItemEditorControls(
  props: SingleWorkItemEditorControlsProps
): JSX.Element {
  const styles = useStyles();
  const { editingWorkItem, schools, saveEdit, cancelEdit } = props;

  const [editsMade, setEditsMade] = React.useState<boolean>(false);
  const [copyOfWorkItem, setCopyOfWorkItem] = React.useState<IWorkItem>(
    copy(editingWorkItem)
  );
  const { therapists } = React.useContext(TherapistContext);
  const settings = React.useContext(SettingsContext);

  const setAttribute = React.useCallback(
    (key: keyof IWorkItem, value: IWorkItem[typeof key]) => {
      setEditsMade(true);
      setCopyOfWorkItem((prev) => ({ ...prev, [key]: value }));
    },
    [setCopyOfWorkItem]
  );

  const setDateWorked = (dateWorked: DateTime | null) => {
    if (!dateWorked) return;
    setAttribute("dateWorked", dateWorked.toISO() ?? "");
  };

  const setSchoolId = (option: DropdownSelectorOption) => {
    setAttribute("schoolId", option.value);
  };

  const setWorkTypeId = (option: DropdownSelectorOption) => {
    setAttribute("workTypeId", option.value);
  };

  const setUnits = (units: number) => setAttribute("units", units);

  const addStudent = (student: IStudent) =>
    setAttribute("students", [...copyOfWorkItem.students, student]);

  const removeStudent = (student: IStudent) =>
    setAttribute("students", [
      ...copyOfWorkItem.students.filter((inList) => inList !== student),
    ]);

  const workTypeId = React.useMemo(
    () => copyOfWorkItem.workTypeId ?? "",
    [copyOfWorkItem]
  );

  const classId = React.useMemo(
    () => copyOfWorkItem.classId ?? "",
    [copyOfWorkItem]
  );

  const className = React.useMemo(
    () => (classId === "" ? "" : settings.classes[classId].name),
    [classId, settings.classes]
  );
  const schoolDropdownOptions = React.useMemo(() => {
    const therapist = therapists.find(
      (t) => t.userId === copyOfWorkItem.therapistUserId
    );
    if (!therapist) return [];
    const filtered = schools.filter((sch) =>
      therapist.schoolIds.find((s) => sch.id === s)
    );
    return makeDropdownOptions(filtered, "name", "id");
  }, [copyOfWorkItem.therapistUserId, schools, therapists]);

  const workTypeDropDownOptions = React.useMemo(
    () =>
      Object.entries(
        classId === "" ? [] : settings.classes[classId].workTypes
      ).map(([workTypeId, workType]) => {
        return {
          label: workType["name"],
          value: workTypeId,
        };
      }),
    [settings.classes, classId]
  );

  const studentOptions = React.useMemo(() => {
    return (
      schools.find((school) => school.id === copyOfWorkItem.schoolId)
        ?.students ?? []
    );
  }, [copyOfWorkItem.schoolId, schools]);

  const onSave = React.useCallback(() => {
    saveEdit(copyOfWorkItem);
    return;
  }, [copyOfWorkItem, saveEdit]);

  const onCancel = React.useCallback(() => {
    cancelEdit(editsMade);
    return;
  }, [cancelEdit, editsMade]);

  const workTypeName = React.useMemo(() => {
    if (classId === "" || workTypeId === "") return "";

    return (
      settings.classes[copyOfWorkItem.classId].workTypes[
        copyOfWorkItem.workTypeId
      ].name ?? ""
    );
  }, [classId, workTypeId, settings.classes, copyOfWorkItem]);

  const unselectedStudents = React.useMemo(() => {
    return studentOptions.filter((option) => {
      return !copyOfWorkItem.students.find((stu) => stu.name === option.name);
    });
  }, [copyOfWorkItem.students, studentOptions]);

  if (editingWorkItem === undefined) return <>Item was null</>;

  return (
    <Paper className={clsx(styles.paper)}>
      <Typography
        component="h3"
        variant="h6"
        color="inherit"
        noWrap
        className={styles.title}
      >
        Edit Work Item
      </Typography>
      <Box className={clsx(styles.editorContainer)}>
        <SimpleDatePicker
          label={"Date Worked"}
          value={copyOfWorkItem.dateWorked}
          onChange={setDateWorked}
        />
        <DropdownSelect
          label={"School"}
          value={{
            label:
              schools.find((s) => s.id === copyOfWorkItem.schoolId)?.name ?? "",
            value: copyOfWorkItem.schoolId,
          }}
          handleChange={(ns) => {
            if (ns) setSchoolId(ns);
          }}
          options={schoolDropdownOptions}
          required
        />

        <PredefinedStudentSelector
          options={unselectedStudents}
          selected={copyOfWorkItem.students}
          addStudent={addStudent}
          removeStudent={removeStudent}
          hideBorder
        />
        <DropdownSelect
          label={"Service"}
          value={{
            label: className,
            value: classId,
          }}
          options={[]}
          handleChange={() => {
            return;
          }}
          isDisabled
        />
        <DropdownSelect
          value={{
            label: workTypeName,
            value: workTypeId,
          }}
          options={workTypeDropDownOptions}
          label={"Work Type"}
          handleChange={(ns) => {
            if (ns) setWorkTypeId(ns);
          }}
          required
        />

        <UnitSelector
          label={"Units"}
          value={copyOfWorkItem.units}
          onChange={setUnits}
        />

        <ConfirmDenyButtonAction
          confirmText="Save"
          denyText="Cancel"
          confirmAction={onSave}
          denyAction={onCancel}
        />
      </Box>
    </Paper>
  );
}
