import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import React from "react";
import clsx from "clsx";
import { ITimesheetRecord } from "../common/interfaces";
import Toaster, { ToastType } from "../common/Toaster";
import { UserContext } from "../context/UserContext";
import { TimesheetContext } from "../context/TimesheetContext";
import { API } from "../context/APIContext";
import { createLogger } from "./Logging/Logging";
import { SimpleDatePicker } from "./Input/SimpleDatePicker";
import { ConfirmDenyButtonAction } from "./Input/ConfirmDenyButtonAction";
import { UnitSelector } from "./Input/UnitSelector";
import { DateTime } from "luxon";
import { formatISO } from "../common/DateUtils";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {},
    centeringColumn: {
      display: "flex",
      flexDirection: "column",
    },
    paper: {
      padding: theme.spacing(2),
      minWidth: "25%",
      marginBottom: "10px",
    },
    centeringRow: {
      display: "flex",
      overflow: "hidden",
      flexDirection: "row",
    },
    vertCentering: {
      margin: "auto 0",
    },
    timePickerLabel: {
      paddingRight: "5px",
      width: "120px",
    },
    input: {
      width: "90%",
      margin: "5px auto",
      minWidth: "10%",
    },
    right: {
      float: "right",
    },
    left: {
      float: "left",
    },
    button: {
      margin: "10px",
    },
  })
);

const logger = createLogger("UserTimesheetSubmissionForm");
interface IUserTimesheetSubmissionFormProps {
  setOpen?: (val: boolean) => void;
  id?: string;
}

export default function UserTimesheetSubmissionForm(
  props: IUserTimesheetSubmissionFormProps
): JSX.Element {
  const { id } = React.useContext(UserContext);
  const { triggerRefresh } = React.useContext(TimesheetContext);
  const [clockIn, setClockIn] = React.useState<DateTime>(
    DateTime.local().set({ hour: 7, minute: 30 })
  );
  React.useEffect(() => {
    setClockIn(clockIn.set({ minute: 30 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [clockOut, setClockOut] = React.useState<DateTime>(
    DateTime.local().set({ hour: 16, minute: 0 })
  );
  const [baseDate, setBaseDate] = React.useState<DateTime>(DateTime.local());
  const [mileage, setMileage] = React.useState<string>("0");
  const [break_units, setBreakUnits] = React.useState<string>("0");
  const api = React.useContext(API);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const classes = useStyles();

  React.useEffect(() => {
    if (errorMessage) {
      Toaster(errorMessage, ToastType.error);
    }
  }, [errorMessage]);

  const handleClock = React.useCallback(
    (newDate: DateTime | null, clock: "clockin" | "clockout") => {
      setErrorMessage(undefined);
      try {
        if (newDate === null) return;
        switch (clock) {
          case "clockin":
            if (newDate.valueOf() >= clockOut.valueOf()) {
              setErrorMessage("Clock In must be before Clock Out");
            } else {
              setClockIn(newDate);
            }
            break;
          case "clockout":
            if (newDate.valueOf() <= clockIn.valueOf()) {
              setErrorMessage("Clock Out must be after Clock In");
            } else {
              setClockOut(newDate);
            }
            break;
        }
      } catch (e) {
        logger.fromError(e);
      }
    },
    [clockIn, clockOut]
  );

  const handleSubmission = React.useCallback(() => {
    const adjustedClockIn = clockIn.set({
      day: baseDate.day,
      month: baseDate.month,
      year: baseDate.year,
    });

    const adjustedClockOut = clockOut.set({
      day: baseDate.day,
      month: baseDate.month,
      year: baseDate.year,
    });

    const record = {
      clockIn: formatISO(adjustedClockIn),
      clockOut: formatISO(adjustedClockOut),
      therapistId: props?.id ?? id,
      mileage: mileage,
      break_units: break_units,
    } as ITimesheetRecord;

    if (errorMessage) {
      Toaster(`${errorMessage}`, ToastType.error);
      return;
    }
    Toaster("Processing timesheet submission...", ToastType.info);
    api.storeTimesheetRecord(record).then(
      async (res) =>
        await res
          .json()
          .then(() => {
            if (res.status === 200) {
              Toaster(`Timesheet submitted`, ToastType.good);
              triggerRefresh();
            } else if (res.status === 423) {
              Toaster(
                `Timesheet Submission Failed: Payroll is locked for that time range`,
                ToastType.error
              );
              logger.error("Timesheet Submission Failed", res);
            } else {
              logger.error("Timesheet Submission Failed", res);
              Toaster(
                `Timesheet Submission Failed: ${res.statusText}`,
                ToastType.error
              );
            }
            props.setOpen?.(false);
          })
          .catch((e) => logger.error(e))
    );
  }, [
    api,
    baseDate,
    break_units,
    clockIn,
    clockOut,
    errorMessage,
    id,
    mileage,
    props,
    triggerRefresh,
  ]);

  return (
    <Paper
      elevation={3}
      className={clsx(classes.paper, classes.centeringColumn)}
    >
      <Typography
        component="h3"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Submit Timesheet
      </Typography>
      <div className={classes.centeringRow}>
        <Typography
          component="div"
          variant="subtitle1"
          color="inherit"
          noWrap
          className={clsx(classes.vertCentering, classes.timePickerLabel)}
        >
          DateTime
        </Typography>
        <SimpleDatePicker
          label={""}
          value={baseDate}
          onChange={function (date: DateTime | null): void {
            if (date !== null) {
              setBaseDate(date);
            }
          }}
        />
      </div>
      <div className={classes.centeringRow}>
        <Typography
          component="div"
          variant="subtitle1"
          color="inherit"
          noWrap
          className={clsx(classes.vertCentering, classes.timePickerLabel)}
        >
          Clock-In
        </Typography>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          inputVariant="outlined"
          value={clockIn}
          onChange={(e: MaterialUiPickersDate) => handleClock(e, "clockin")}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          error={errorMessage !== undefined}
        />
      </div>
      <div className={classes.centeringRow}>
        <Typography
          component="div"
          variant="subtitle1"
          color="inherit"
          noWrap
          className={clsx(classes.vertCentering, classes.timePickerLabel)}
        >
          Clock-Out
        </Typography>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          inputVariant="outlined"
          value={clockOut}
          onChange={(e: MaterialUiPickersDate) => handleClock(e, "clockout")}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          error={errorMessage !== undefined}
        />
      </div>
      <div
        className={classes.centeringRow}
        style={{ gap: "10px", margin: "10px" }}
      >
        <UnitSelector
          label={"Mileage"}
          value={parseInt(mileage)}
          onChange={(newValue: number) => {
            if (newValue > 0) setMileage(newValue.toString());
          }}
        />
        <UnitSelector
          label={"Break Units"}
          value={parseInt(break_units)}
          onChange={(newValue: number) => {
            if (newValue > 0) setBreakUnits(newValue.toString());
          }}
        />
      </div>
      <ConfirmDenyButtonAction
        confirmAction={handleSubmission}
        confirmText="Submit"
        denyAction={() => {
          props.setOpen?.(false);
        }}
        denyText="Cancel"
      />
    </Paper>
  );
}
