import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ChangeEventHandler } from "react";
import { clsx } from "../../clsx";
import { DropdownSelectorOption, ITherapist } from "../../common/interfaces";
import DropdownSelect from "../Input/DropdownSelect";
import { SettingsContext } from "../../context/SettingsContext";
import { isObjectEmpty } from "../../common/Utils";

export interface IndividualStaffViewStaffEditFormProps {
  editingTherapist: ITherapist;
  nameError: boolean;
  classOptions: DropdownSelectorOption[];
  classError: boolean;
  gradeOptions: DropdownSelectorOption[];
  gradeError: boolean;
  onNameChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClassificationChange: (option: DropdownSelectorOption) => void;
  onGradeChange: (option: DropdownSelectorOption) => void;
}

const useStyles = makeStyles({
  editingControls: {
    padding: "10px",
  },
  maxContentHeight: {
    maxHeight: "90vh",
    overflowy: "auto",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  input: {
    width: "95%",
    margin: "5px auto",
    minWidth: "10%",
  },
  inlineContainer: {
    display: "inline",
  },
  button: {
    margin: "10px",
  },
  editForm: {
    // minHeight: "700px"
  },
  table: {
    width: "50%",
  },
});

export function IndividualStaffViewStaffEditForm(
  props: IndividualStaffViewStaffEditFormProps
): JSX.Element {
  const {
    editingTherapist,
    nameError,
    classOptions,
    classError,
    gradeOptions,
    gradeError,
    onNameChange,
    onClassificationChange,
    onGradeChange,
  } = props;

  const { classes } = React.useContext(SettingsContext);

  const styles = useStyles();
  return (
    <div className={clsx(styles.flexColumn, styles.editingControls)}>
      <TextField
        className={styles.input}
        label="Name"
        variant="outlined"
        value={editingTherapist.name}
        onChange={onNameChange}
        error={nameError}
      />
      <TextField
        className={styles.input}
        label="User ID"
        variant="outlined"
        value={editingTherapist.userId}
        disabled={true}
      />
      <DropdownSelect
        className={styles.input}
        label="Classification"
        value={{
          label:
            editingTherapist.classId === "" || isObjectEmpty(classes)
              ? ""
              : classes[editingTherapist.classId].name,
          value: editingTherapist.classId,
        }}
        handleChange={(ns) => {
          if (ns) onClassificationChange(ns);
        }}
        options={classOptions}
        error={classError}
        clearOnSelect
      />
      <DropdownSelect
        className={styles.input}
        label="Grade"
        value={{
          label:
            editingTherapist.classId === "" ||
            editingTherapist.gradeId === "" ||
            isObjectEmpty(classes)
              ? ""
              : classes[editingTherapist.classId].grades[
                  editingTherapist.gradeId
                ].name,
          value: editingTherapist.gradeId,
        }}
        handleChange={(ns) => {
          if (ns) onGradeChange(ns);
        }}
        options={gradeOptions}
        error={gradeError}
        clearOnSelect
      />
    </div>
  );
}
