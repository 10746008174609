import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

interface NoTableContentProps {
  numCols?: number;
  text?: string;
}

const useStyles = makeStyles({
  row: {
    height: '100%',
    width: '100%'
  },
  container: {
    height: '400px',
  },
  cell: {
    alignItems: 'center'
  }
});

export function NoTableContent(props: NoTableContentProps): JSX.Element {
  const {
    numCols,
    text
  } = props;
  const styles = useStyles();
  return (
    <TableRow className={styles.row}>
      <TableCell colSpan={numCols ?? 1} className={styles.cell}>
        <Typography>{text ?? 'No Content'}</Typography>
      </TableCell>
    </TableRow>   
  );
}