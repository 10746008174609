import { makeStyles, createStyles } from "@material-ui/styles";
import React, { ChangeEvent } from "react";
import { Fragment } from "react";
import { TherapistContext } from "../context/TherapistContext";
import clsx from "clsx";
import { SettingsContext } from "../context/SettingsContext";
import { SchoolContext } from "../context/SchoolContext";
import WorkItemTable from "../components/WorkItemTable";
import { WorkItemContext } from "../context/WorkItemContext";
import Toaster, { ToastType } from "../common/Toaster";
import TimeSheetTable from "../components/TimeSheetTable";
import { TimesheetContext } from "../context/TimesheetContext";
import { IndividualStaffViewHeader } from "../components/IndividualStaffView/IndividualStaffViewHeader";
import { IndividualStaffViewStaffEditForm } from "../components/IndividualStaffView/IndividualStaffViewStaffEditForm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  ITherapist,
  IWorkItem,
  ISchool,
  DropdownSelectorOption,
} from "../common/interfaces";
import DropdownSelect from "../components/Input/DropdownSelect";
import { API } from "../context/APIContext";
import { useHistory, useParams } from "react-router-dom";
import { createLogger } from "../components/Logging/Logging";
import { SchoolSelectionTable } from "../components/IndividualStaffView/SchoolSelectionTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmationModal from "../components/ConfirmationModal";
import { BoldNumberDisplay } from "../components/Display/BoldNumberDisplay";
import Eye from "../components/Input/Eye";
import { RangeDatePicker } from "../components/RangeDatePicker/RangeDatePicker";
import LoadingSplash from "../components/Display/LoadingSplash";
import { DateTime } from "luxon";
import { getSchoolById } from "../common/Utils";
const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pageContentArea: {
      maxHeight: "95%",
      maxWidth: "95%",
    },
    paper: {
      borderRadius: "10px",
    },
    inlineContainer: {
      display: "inline",
    },
    centeringContainer: {
      display: "block",
      margin: "auto",
    },
    defaultMessage: {
      fontSize: 18,
      textAlign: "center",
    },
    button: {
      margin: "10px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    label: {
      textAlign: "center",
      fontSize: "12",
      width: "100%",
      margin: "auto",
    },
    error: {
      border: "1px red solid",
    },
    scrollable: {
      overflowY: "auto",
    },
    fixedMaxHieght: {
      maxHeight: "300px",
    },
    input: {
      width: "95%",
      margin: "5px auto",
      minWidth: "10%",
    },
    editingControls: {
      padding: "10px",
    },
    editForm: {
      // minHeight: "700px"
    },
    maxContentHeight: {
      maxHeight: "90vh",
      overflowy: "auto",
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    table: {
      padding: "5px",
    },
    timesheetTable: {
      width: "45%",
    },
    workItemTable: {
      width: "55%",
    },
    heading: {
      borderRadius: "5px",
    },
    workItemTableToolBarDropdown: {
      width: "40%",
    },
  })
);

const logger = createLogger("IndividualStaffView");

export default function IndividualStaffView(): JSX.Element {
  const therapistContext = React.useContext(TherapistContext);
  const schoolContext = React.useContext(SchoolContext);
  const api = React.useContext(API);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    const lookup = therapistContext.therapists.find(
      (therapist) => therapist.userId === id
    );
    if (lookup) therapistContext.setSelectedTherapist(lookup);
  }, [id, therapistContext]);

  const {
    filteredWorkItems,
    setShowInvoiced,
    setStartDate,
    setEndDate,
    showInvoiced,
    start,
    end,
    timeRanges,
    thisWeek,
    school,
    setSchool,
  } = React.useContext(WorkItemContext);
  const { classes } = React.useContext(SettingsContext);
  const { schools } = React.useContext(SchoolContext);
  const { timesheetRecords } = React.useContext(TimesheetContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [cancelConfirmation, setShowCancelConfirmation] =
    React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [classError, setClassError] = React.useState<boolean>(false);
  const [gradeError, setGradeError] = React.useState<boolean>(false);
  const styles = useStyles();

  const [editingTherapist, setEditingTherapist] = React.useState<
    ITherapist | undefined
  >(therapistContext.selectedTherapist);

  React.useEffect(
    () => setEditingTherapist(therapistContext.selectedTherapist),
    [therapistContext.selectedTherapist]
  );

  const setAttribute = React.useCallback(
    (key: keyof ITherapist, value: unknown) => {
      if (!editingTherapist) {
        logger.error(`editingTherapist DNE; Failed ${key} <= ${value}`);
        return;
      }
      if (key in editingTherapist) {
        logger.info(`Updating editingTherapist: ${key} <= ${value}`);
        setEditingTherapist({ ...editingTherapist, [key]: value });
      } else {
        logger.error(`${key} not in  ${editingTherapist}`);
      }
    },
    [editingTherapist]
  );

  const therapistWorkItems = React.useMemo(
    () =>
      filteredWorkItems?.filter(
        (wi: IWorkItem) => wi.therapistUserId === editingTherapist?.userId
      ),
    [editingTherapist?.userId, filteredWorkItems]
  );

  const rowsForWorkItemTable = React.useMemo(() => {
    return therapistWorkItems.filter((item) =>
      school === "All" ? true : item.schoolId === school
    );
  }, [school, therapistWorkItems]);

  const handleNameChange = React.useCallback(
    (newName: string) => {
      if (editingTherapist === undefined) return;
      setAttribute("name", newName);
      setHasChanges(true);
    },
    [editingTherapist, setAttribute]
  );

  const handleClassChange = React.useCallback(
    (newClassId: string) => {
      if (editingTherapist === undefined) return;

      setEditingTherapist({
        ...editingTherapist,
        classId: newClassId,
        gradeId: "",
      });
      setGradeError(true);
      setHasChanges(true);
    },
    [editingTherapist]
  );

  const handleGradeChange = React.useCallback(
    (newGradeId: string) => {
      if (editingTherapist === undefined) return;
      if (!(newGradeId in classes[editingTherapist.classId].grades)) return;

      setAttribute("gradeId", newGradeId);
      setHasChanges(true);
    },
    [classes, editingTherapist, setAttribute]
  );

  const handleAddSchool = React.useCallback(
    (newSchoolId: string) => {
      if (editingTherapist === undefined) return;
      const school = schools.find((s: ISchool) => s.id === newSchoolId);
      if (school === undefined) return;
      setAttribute("schoolIds", [...editingTherapist.schoolIds, school.id]);
      setHasChanges(true);
    },
    [editingTherapist, schools, setAttribute]
  );

  const handleRemoveSchool = React.useCallback(
    (oldSchoolName: string) => {
      const oldSchoolId = schools.find(
        (school) => `${school.name} ${school.invoicePrefix}` === oldSchoolName
      )?.id;
      if (oldSchoolId === undefined || oldSchoolId.length === 0) {
        logger.error(
          `handleRemoveSchool => School ${oldSchoolName} does not exist`,
          {
            oldSchoolName,
            schools,
          }
        );
        Toaster(
          `Error: ${oldSchoolName} is not a valid district, please contact your administrator`,
          ToastType.error
        );
        logger.error(`${oldSchoolName} is not a valid district`, {
          oldSchoolName,
          oldSchoolId,
          schools,
        });
        return;
      }
      if (editingTherapist === undefined) {
        logger.error("handleRemoveSchool => editingTherapist is undefined");
        return;
      }
      const found = editingTherapist.schoolIds.find(
        (sid: string) => sid === oldSchoolId
      );
      if (!found) {
        logger.error(
          `handleRemoveSchool => could not find school in editingTherapist.schoolIds` +
            `schoolId => ${oldSchoolId}` +
            `schoolIds => ${editingTherapist.schoolIds}`,
          { oldSchoolId, schools, editingTherapist }
        );
        return;
      }
      setAttribute(
        "schoolIds",
        editingTherapist.schoolIds.filter((sid: string) => sid !== oldSchoolId)
      );
      setHasChanges(true);
    },
    [editingTherapist, schools, setAttribute]
  );

  const saveChanges = React.useCallback(() => {
    if (nameError || classError || gradeError || !editingTherapist) {
      if (nameError) Toaster("Invalid Name", ToastType.error);
      if (classError) Toaster("Invalid Class", ToastType.error);
      if (gradeError) Toaster("Invalid Grade", ToastType.error);
      if (!editingTherapist)
        Toaster("Editing Therapist Undefined; Contact Admin", ToastType.error);
      return;
    }
    if (editingTherapist.name.length === 0) {
      logger.error("editingTherapist.name.length === 0");
      setNameError(true);
      return;
    }
    if (editingTherapist.gradeId === undefined) {
      Toaster(
        "Selected Grade is not valid; Make sure selected Service has grades",
        ToastType.error
      );
      logger.error("editingTherapist.gradeName === undefined");
      setGradeError(true);
      return;
    }

    if (therapistContext.selectedTherapist === undefined) {
      logger.error("therapistContext.selectedTherapist === undefined");
      return;
    }
    api
      .editTherapist({
        id: therapistContext.selectedTherapist._id,
        updateObj: editingTherapist,
      })
      .then((res) => {
        res.json().then((data) => {
          if (res.status === 200) {
            therapistContext.triggerRefresh();
            Toaster(`${editingTherapist.name} updated.`, ToastType.good);
          } else {
            Toaster(
              `Error ${res.statusText}: ${data.message}`,
              ToastType.error
            );
          }
        });
      });
  }, [
    api,
    classError,
    editingTherapist,
    gradeError,
    nameError,
    therapistContext,
  ]);

  const cancelChanges = React.useCallback(() => {
    setEditingTherapist(undefined);
    therapistContext.setSelectedTherapist(undefined);
    history.push("/staff");
  }, [history, therapistContext]);

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirmation(true);
    } else {
      cancelChanges();
    }
  }, [cancelChanges, hasChanges]);

  const onNameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNameError(false);
    handleNameChange(e.target.value);
  };

  const onClassificationChange = (option: DropdownSelectorOption) => {
    setClassError(false);
    handleClassChange(option.value);
  };

  const onGradeChange = (option: DropdownSelectorOption) => {
    setGradeError(false);
    handleGradeChange(option.value);
  };

  const classOptions = React.useMemo(() => {
    return Object.entries(classes).map(([id, clss]) => ({
      label: clss.name,
      value: id,
    }));
  }, [classes]);

  const gradeOptions = React.useMemo(() => {
    if (
      editingTherapist === undefined ||
      !(editingTherapist.classId in classes)
    )
      return [];

    return Object.entries(classes[editingTherapist.classId].grades).map(
      ([id, grade]) => ({
        label: grade.name,
        value: id,
      })
    );
  }, [classes, editingTherapist]);

  const handleSchoolChange = React.useCallback(
    (option: DropdownSelectorOption) => setSchool(option.value),
    [setSchool]
  );

  const options: DropdownSelectorOption[] = schoolContext.schools
    .filter((sch) =>
      therapistContext.selectedTherapist?.schoolIds.find((id) => id === sch.id)
    )
    .map((school) => ({
      label: `${school.name} ${school.invoicePrefix}`,
      value: school.id,
    }));

  const workItemAdminToolbar = React.useMemo(() => {
    const totalUnitHours =
      therapistWorkItems.map((r) => r.units).reduce((a, u) => a + u, 0) / 4;
    const districtUnitHours =
      rowsForWorkItemTable.map((r) => r.units).reduce((a, u) => a + u, 0) / 4;
    const _school = getSchoolById(school, schools);
    const _schoolName = _school?.name ?? "Unknown";
    return (
      <div style={{ display: "flex", width: "100%", gap: "10px" }}>
        <DropdownSelect
          className={styles.workItemTableToolBarDropdown}
          label={"School"}
          value={{
            label:
              _schoolName === "All"
                ? _schoolName
                : `${_schoolName} ${_school?.invoicePrefix ?? "Unknown"}`,
            value: school,
          }}
          handleChange={(ns) => {
            if (ns) handleSchoolChange(ns);
            else setSchool("All");
          }}
          options={options}
          includeAll={true}
          isClearable
        />
        <div style={{ height: "90%", marginTop: "10px" }}>
          <BoldNumberDisplay
            label={"District"}
            num={districtUnitHours}
            unit={"Hours"}
            variant={"h4"}
            compact
          />
        </div>
        <div style={{ height: "90%", marginTop: "10px" }}>
          <BoldNumberDisplay
            label={"Total"}
            num={totalUnitHours}
            unit={"Hours"}
            variant={"h4"}
            compact
          />
        </div>
        <div style={{ height: "90%" }}>
          <RangeDatePicker
            start={start}
            end={end}
            predefinedOptions={Array.from(timeRanges.values())}
            setStart={(start: DateTime | null) => setStartDate(start)}
            setEnd={(end: DateTime | null) => setEndDate(end)}
          />
        </div>
        <div style={{ padding: "10px" }}>
          <Eye
            visible={showInvoiced}
            toggle={() => setShowInvoiced(!showInvoiced)}
          />
        </div>
      </div>
    );
  }, [
    end,
    handleSchoolChange,
    options,
    rowsForWorkItemTable,
    school,
    setEndDate,
    setSchool,
    setShowInvoiced,
    setStartDate,
    showInvoiced,
    start,
    styles.workItemTableToolBarDropdown,
    therapistWorkItems,
    timeRanges,
    schools,
  ]);

  const columnsToHide = ["therapist", "dateLogged"];

  if (!editingTherapist || !therapistContext.selectedTherapist)
    return <LoadingSplash />;
  return (
    <Fragment>
      <Paper elevation={1} className={clsx(styles.editForm)}>
        <div
          className={clsx(
            styles.paper,
            styles.pageContentArea,
            styles.centeringContainer
          )}
        >
          <IndividualStaffViewHeader
            title={`${
              therapistContext?.selectedTherapist?.name ?? "undefined"
            }`}
            cancel={handleCancel}
            save={saveChanges}
          />
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={styles.heading}>
                  Staff Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  <IndividualStaffViewStaffEditForm
                    editingTherapist={editingTherapist}
                    nameError={nameError}
                    gradeError={gradeError}
                    classError={classError}
                    classOptions={classOptions}
                    gradeOptions={gradeOptions}
                    onNameChange={onNameChange}
                    onClassificationChange={onClassificationChange}
                    onGradeChange={onGradeChange}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <SchoolSelectionTable
                    selected={editingTherapist.schoolIds}
                    addSchool={handleAddSchool}
                    removeSchool={handleRemoveSchool}
                    maxHeight={200}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={clsx(styles.flexRow, styles.spaceBetween)}>
            <div className={clsx(styles.table, styles.timesheetTable)}>
              <TimeSheetTable
                selectedTherapist={editingTherapist}
                timesheetRecords={timesheetRecords}
                tableContainerProps={{
                  maxHeight: "400px",
                  overflowY: "scroll",
                }}
                columnsToHide={["therapistId"]}
                start={start}
                end={end}
                timeRanges={timeRanges}
                setStartDate={(start: DateTime | null) => setStartDate(start)}
                setEndDate={(end: DateTime | null) => setEndDate(end)}
                defaultOption={thisWeek}
                hideReportButton
              />
            </div>
            <div className={clsx(styles.table, styles.workItemTable)}>
              <Paper elevation={3}>
                <WorkItemTable
                  rows={rowsForWorkItemTable}
                  selected={[]}
                  setSelected={() => null}
                  columnsToHide={columnsToHide}
                  tableContainerProps={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                  therapist={editingTherapist}
                  showNewButton
                  start={start}
                  end={end}
                  showInvoicedWorkItems={showInvoiced}
                  timeRanges={timeRanges}
                  setStartDate={(start: DateTime | null) => setStartDate(start)}
                  setEndDate={(end: DateTime | null) => setEndDate(end)}
                  setShowInvoicedWorkItems={(show: boolean) =>
                    setShowInvoiced(show)
                  }
                  altToolbar={workItemAdminToolbar}
                  listStudents
                />
              </Paper>
            </div>
          </div>
        </div>
      </Paper>
      <ConfirmationModal
        message="Changes have been made. Are you sure you want to cancel?"
        confirmText="No, keep editing"
        cancelText="Yes, cancel"
        onConfirm={function (): void {
          setShowCancelConfirmation(false);
        }}
        onCancel={function (): void {
          cancelChanges();
        }}
        open={cancelConfirmation}
        setOpen={function (open: boolean): void {
          setShowCancelConfirmation(open);
        }}
      />
    </Fragment>
  );
}
