import DeleteIcon from '@material-ui/icons/Delete';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EventIcon from '@material-ui/icons/Event';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export type IconType = JSX.Element;

const BrightModeIcon = Brightness7Icon;
const DarkModeIcon = Brightness4Icon;

export {
  DeleteIcon,
  TimerOutlinedIcon,
  EditIcon,
  CancelOutlinedIcon,
  SaveOutlinedIcon,
  AddIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  ArrowDownwardIcon,
  LockIcon,
  MenuIcon,
  PeopleAltIcon,
  ChevronLeftIcon,
  ApartmentIcon,
  MonetizationOnIcon,
  DescriptionIcon,
  SettingsIcon,
  AssignmentTurnedInOutlinedIcon,
  ArrowForwardIosIcon,
  EventIcon,
  KeyboardBackspaceIcon,
  WarningRoundedIcon,
  AccountCircleIcon,
  BrightModeIcon,
  DarkModeIcon,
  ExitToAppIcon,
};