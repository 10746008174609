import React from "react";
import TokenContextProvider from "./context/TokenContext";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import APIContextProvider from "./context/APIContext";
import SchoolContextProvider from "./context/SchoolContext";
import SettingsContextProvider from "./context/SettingsContext";
import TherapistContextProvider from "./context/TherapistContext";
import TimesheetContextProvider from "./context/TimesheetContext";
import UserContextProvider from "./context/UserContext";
import WorkItemContextProvider from "./context/WorkItemContext";
import { AppThemeContextProvider } from "./AppTheme";
import { ToastContainer } from "react-toastify";

interface ContextProps {
  token: string;
}
export default function AppContextProvider(
  props: React.PropsWithChildren<ContextProps>
): JSX.Element {
  const { token, children } = props;

  return (
    <div id="app" className="d-flex flex-column h-100">
      <AppThemeContextProvider>
        <TokenContextProvider token={token}>
          <APIContextProvider>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <UserContextProvider>
                <SettingsContextProvider>
                  <SchoolContextProvider>
                    <TherapistContextProvider>
                      <WorkItemContextProvider>
                        <TimesheetContextProvider>
                          {children}
                        </TimesheetContextProvider>
                      </WorkItemContextProvider>
                    </TherapistContextProvider>
                  </SchoolContextProvider>
                </SettingsContextProvider>
              </UserContextProvider>
            </MuiPickersUtilsProvider>
          </APIContextProvider>
        </TokenContextProvider>
      </AppThemeContextProvider>
      <ToastContainer />
    </div>
  );
}
