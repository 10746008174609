import { DatePicker } from "@material-ui/pickers";
import React from "react";
import { Box, Button, Grid, Menu } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { clsx } from "clsx";
import { IDateRange } from "../../common/interfaces";
import { WorkItemContext } from "../../context/WorkItemContext";
import { DateTime } from "luxon";
export interface RangeDatePickerDialogProps {
  start: DateTime;
  end: DateTime;
  predefinedOptions?: IDateRange[];
  menuAnchor: HTMLElement | null;
  defaultOption?: IDateRange;
  setStart: (start: DateTime | null) => void;
  setEnd: (end: DateTime | null) => void;
  setMenuAnchor: (anchor: HTMLElement | null) => void;
}

const useStyles = makeStyles({
  predefinedOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    paddingRight: "0xp",
  },
  predefinedOptionButton: {
    borderRadius: "5px",
  },
  predefinedButton: {
    textTransform: "none",
  },
  calendarButtonSep: {
    borderLeft: "2px solid rgba(0, 0, 0, 0.24)",
    borderRadius: "4px 0 0 4px",
  },
});

export default function RangeDatePickerDialog(
  props: RangeDatePickerDialogProps
): JSX.Element {
  const {
    start,
    end,
    predefinedOptions,
    menuAnchor,
    setStart,
    setEnd,
    setMenuAnchor,
  } = props;
  const styles = useStyles();
  const wic = React.useContext(WorkItemContext);
  const [selectedPredefined, setSelectedPredefined] =
    React.useState<IDateRange | null>(
      props.defaultOption ?? wic.thisWeek ?? predefinedOptions?.at(0) ?? null
    );
  const isMenuOpen = Boolean(menuAnchor);

  const handleCloseDatePicker = React.useCallback(() => {
    setMenuAnchor(null);
  }, [setMenuAnchor]);

  const handleSetStart = React.useCallback(
    (date: DateTime | null) => {
      if (!date) return;
      setStart(date.startOf("day"));
      if (date.valueOf() >= end.valueOf()) {
        setEnd(date.endOf("day"));
      }
    },
    [end, setEnd, setStart]
  );

  const handleSetEnd = React.useCallback(
    (date: DateTime | null) => {
      if (!date) return;
      setEnd(date.endOf("day"));
      if (date.valueOf() <= start.valueOf()){
        setStart(date.startOf("day"));
      } 
    },
    [setEnd, setStart, start]
  );

  const predefinedOptionButtons = React.useMemo(() => {
    if (!predefinedOptions) return <></>;
    return (
      <>
        {predefinedOptions.map((option: IDateRange, index: number) => {
          const isSelected = option.title === selectedPredefined?.title;
          return (
            <Button
              className={styles.predefinedButton}
              key={`option-${index}`}
              color={isSelected ? "primary" : "default"}
              variant={isSelected ? "contained" : "text"}
              onClick={() => {
                setSelectedPredefined(option);
                setStart(option.start);
                setEnd(option.end);
                handleCloseDatePicker();
              }}
            >
              {option.title}
            </Button>
          );
        })}
      </>
    );
  }, [
    handleCloseDatePicker,
    predefinedOptions,
    selectedPredefined?.title,
    setEnd,
    setStart,
    styles.predefinedButton,
  ]);

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        id="DateTime-picker-menu"
        open={isMenuOpen}
        onClose={handleCloseDatePicker}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <Grid item>
          <Box sx={{ display: "flex", alignContent: "center" }}>
            {predefinedOptions && (
              <Box className={clsx(styles.predefinedOptionsContainer)}>
                {predefinedOptionButtons}
              </Box>
            )}
            <div className={styles.calendarButtonSep}>
              <DatePicker
                disableToolbar
                variant="static"
                orientation="portrait"
                value={start}
                onChange={handleSetStart}
              />
            </div>
            <DatePicker
              minDate={start}
              disableToolbar
              variant="static"
              orientation="landscape"
              value={end}
              onChange={handleSetEnd}
            />
          </Box>
        </Grid>
      </Menu>
    </>
  );
}
