import React from "react";
import "../app.css";

export interface LoadingProps {
  message?: string;
  timeoutMessage?: string;
  timeoutDuration?: number;
}

export default function Loading(props: LoadingProps): JSX.Element {
  const message = props.message ?? "Loading RSP";
  const [showTimeoutMessage, setShowTimeOutMessage] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (!props.timeoutMessage) return;

    const timeout_id = setTimeout(() => {
      setShowTimeOutMessage(true);
    }, props.timeoutDuration ?? 5000);
    return () => {
      clearTimeout(timeout_id);
    };
  }, [props.timeoutDuration, props.timeoutMessage]);

  return (
    <>
      <div
        id="splash"
        style={{
          backgroundImage: "linear-gradient(-105deg, #009acc, #363795)",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="splashText">
            <div>{message}</div>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            {showTimeoutMessage && (
              <div style={{ fontSize: "28px" }}>{props.timeoutMessage}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
