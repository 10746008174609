import React from "react";

import { IInvoiceObject } from "../common/interfaces";
import SortableTable from "./Display/Table/SortableTable";
import { OverrideScript } from "../common/Script";
import { ArrowDownwardIcon, ExitToAppIcon } from "../icons";
import { DateTime } from "luxon";
import { SchoolContext } from "../context/SchoolContext";

export interface InvoiceTableProps {
  invoices: IInvoiceObject[];
  onDownload: (inv: IInvoiceObject) => void;
  onExport: (inv: IInvoiceObject) => void;
}

export default function InvoiceTable(props: InvoiceTableProps): JSX.Element {
  const { schools } = React.useContext(SchoolContext);
  const script: OverrideScript<IInvoiceObject> = React.useMemo(() => {
    return [
      {
        id: "invoiceNumber",
        text: "Number",
        getField: (member: IInvoiceObject) => member.invoiceNumber,
      },
      {
        id: "invoiceDate",
        text: "Date",
        getField: (member: IInvoiceObject) => member.invoiceDate,
        comparator: (
          a: IInvoiceObject,
          b: IInvoiceObject,
          orderBy: keyof IInvoiceObject
        ) => {
          const date_a = DateTime.fromFormat(a[orderBy], "LL/dd/yyyy");
          const date_b = DateTime.fromFormat(b[orderBy], "LL/dd/yyyy");
          if (date_a > date_b) return -1;
          if (date_a < date_b) return 1;
          return 0;
        },
      },
      {
        id: "schoolId",
        text: "School",
        getField: (member: IInvoiceObject) =>
          schools.find((sch) => sch.id === member.schoolId)?.name ?? "Unknown",
      },
      {
        id: "classification",
        text: "Classification",
        getField: (member: IInvoiceObject) => member.classification,
      },
      {
        id: "action",
        text: "Download/Export",
        align: "right",
        isAction: true,
        action: [props.onDownload, props.onExport],
        actionIcon: [
          <ArrowDownwardIcon key={"download"} />,
          <ExitToAppIcon key={"export"} />,
        ],
      },
    ];
  }, [props.onDownload, props.onExport, schools]);
  return (
    <SortableTable
      label={"Invoice Table"}
      script={script}
      rows={props.invoices}
      defaultSortKey="invoiceDate"
      defaultSortOrder="desc"
    />
  );
}
