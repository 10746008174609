import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import {
  DropdownSelectorOption,
  ITimesheetRecord,
  IWorkItem,
} from "../common/interfaces";
import DropdownSelect from "../components/Input/DropdownSelect";
import WorkItemTable from "../components/WorkItemTable";
import { SchoolContext } from "../context/SchoolContext";
import { WorkItemContext } from "../context/WorkItemContext";
import BasicView from "./BasicView";
import clsx from "clsx";
import WorkItemFormModal from "../components/WorkItemFormModal";
import UserTimesheetSubmissionFormModal from "../components/UserTimesheetFormModal";
import { UserActions } from "../components/Input/UserActions";
import { TimesheetContext } from "../context/TimesheetContext";
import { toInterval, unitsToHours } from "../common/Utils";
import * as durationMath from "duration-fns";
import { SideBySide } from "../components/Display/SideBySide";
import { UserContext } from "../context/UserContext";
import LoadingSpinner from "../components/LoadingSpinner";
import TimeSheetTable from "../components/TimeSheetTable";
import LoadingSplash from "../components/Display/LoadingSplash";
import { DateTime, Interval } from "luxon";
import { user_view_loading_missing_therapist } from "../common/Messages";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px",
    width: "100%",
  },
  root: {
    width: "100%",
  },
  flexRow: {
    display: "inline-block",
    // flexDirection: "row",
    // flexGrow: 1
  },
  right: {
    float: "right",
  },
  left: {
    float: "left",
  },
  inlineContainer: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "5px",
  },
  schoolDropdownSelect: {
    width: "50%",
    marginLeft: "auto",
  },
  flexContainer: {
    display: "flex",
  },
  pushRight: {
    marginLeft: "auto",
  },
  schoolSelect: {},
  unitsVsHoursBase: {
    borderRadius: "4px",
    padding: "5px",
  },
  durationMismatch: {
    border: "1px solid red",
  },
  durationMatch: {
    border: "1px solid green",
  },
  sideBySide: {
    padding: "10px",
    marginLeft: "auto",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    [theme.breakpoints.down(750)]: {
      flexDirection: "column",
      overflowY: "scroll",
    },
  },
  table: {
    width: "50%",
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },
  },
}));

export default function UserView(): JSX.Element {
  const classes = useStyles();
  const schoolContext = React.useContext(SchoolContext);
  const {
    periodWorkItems,
    filteredWorkItems,
    setSchool,
    setStartDate,
    setEndDate,
    setShowInvoiced,
    school,
    start,
    end,
    isLoading,
    timeRanges,
    showInvoiced,
  } = React.useContext(WorkItemContext);
  const timesheetContext = React.useContext(TimesheetContext);
  const { therapist } = React.useContext(UserContext);
  const [selected, setSelected] = React.useState<IWorkItem[]>([]);
  const [showWorkItemForm, setShowWorkItemForm] =
    React.useState<boolean>(false);
  const [showTimesheetForm, setShowTimesheetForm] =
    React.useState<boolean>(false);

  const options: DropdownSelectorOption[] = schoolContext.schools
    .filter((sch) => therapist?.schoolIds.find((id) => id === sch.id))
    .map((school) => ({
      label: school.name,
      value: school.id,
    }));

  const isWithinCurrentTimeRange = React.useCallback(
    (sheet: ITimesheetRecord) => {
      if (start.valueOf() >= end.valueOf()) return false;
      const currentInterval = toInterval(start, end);
      return (
        currentInterval.contains(DateTime.fromISO(sheet.clockIn)) &&
        currentInterval.contains(DateTime.fromISO(sheet.clockOut))
      );
    },
    [start, end]
  );

  const convertSheetToInterval = (sheet: ITimesheetRecord) => {
    return toInterval(
      DateTime.fromISO(sheet.clockIn),
      DateTime.fromISO(sheet.clockOut)
    );
  };

  const applicableTimesheets = timesheetContext.timesheetRecords.filter(
    isWithinCurrentTimeRange
  );

  const totalTimeSheet =
    durationMath.toHours(
      applicableTimesheets
        .map(convertSheetToInterval)
        .map(
          (interval: Interval) =>
            interval.toDuration("milliseconds").toObject().milliseconds ?? 0
        )
        .reduce((prev, curr) => durationMath.sum(prev, curr), {})
    ) -
    applicableTimesheets
      .map((sheet) => parseInt(sheet.break_units))
      .reduce((prev, curr) => prev + curr, 0) *
      0.25;

  const totalUnits = unitsToHours(
    periodWorkItems
      .filter((item) => !item.invoiceId)
      .reduce((prev, curr) => prev + curr.units, 0)
  );

  const workItemRows = React.useMemo(() => {
    return filteredWorkItems;
  }, [filteredWorkItems]);

  const handleSchoolChange = React.useCallback(
    (option: DropdownSelectorOption) => setSchool(option.value),
    [setSchool]
  );

  if (!therapist)
    return (
      <LoadingSplash
        message={"Loading User View"}
        timeoutMessage={user_view_loading_missing_therapist}
      />
    );

  return (
    <BasicView>
      <UserActions
        newTimeSheetAction={() => {
          setShowTimesheetForm(true);
        }}
        newWorkItemAction={() => {
          setShowWorkItemForm(true);
        }}
      />
      <div className={classes.sideBySide}>
        <SideBySide totalTimeSheet={totalTimeSheet} totalUnits={totalUnits} />
      </div>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div
              className={clsx(
                classes.inlineContainer,
                classes.schoolDropdownSelect
              )}
            >
              <DropdownSelect
                label={"School"}
                value={{
                  label: school,
                  value: school,
                }}
                handleChange={(ns) => {
                  if (ns) handleSchoolChange(ns);
                  else setSchool("All");
                }}
                options={options}
                className={classes.schoolSelect}
                includeAll={true}
                isClearable
              />
            </div>

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className={classes.tableContainer}>
                <div className={classes.table}>
                  <TimeSheetTable
                    timesheetRecords={timesheetContext.timesheetRecords}
                    selectedTherapist={therapist}
                    start={start}
                    end={end}
                    timeRanges={timeRanges}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    columnsToHide={["therapistId"]}
                    hideMenuButton
                  />
                </div>
                <div className={classes.table}>
                  <WorkItemTable
                    rows={workItemRows}
                    selected={selected}
                    setSelected={setSelected}
                    columnsToHide={["therapist", "invoiceId", "dateLogged"]}
                    listStudents
                    start={start}
                    end={end}
                    showInvoicedWorkItems={showInvoiced}
                    timeRanges={timeRanges}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setShowInvoicedWorkItems={(show: boolean) =>
                      setShowInvoiced(show)
                    }
                    therapist={therapist}
                  />
                </div>
              </div>
            )}

            {therapist && (
              <WorkItemFormModal
                therapist={therapist}
                open={showWorkItemForm}
                setOpen={setShowWorkItemForm}
              />
            )}

            <UserTimesheetSubmissionFormModal
              open={showTimesheetForm}
              setOpen={setShowTimesheetForm}
            />
          </Paper>
        </div>
      </Grid>
    </BasicView>
  );
}
