import { Box, Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { IDateRange } from "../../common/interfaces";
import RangeDatePickerDialog from "./RangeDatePickerDialog";
import EventIcon from "@material-ui/icons/Event";
import { makeStyles } from "@material-ui/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { DateTime } from "luxon";
import { toInterval } from "../../common/Utils";
export interface RangeDatePickerProps {
  start: DateTime;
  end: DateTime;
  predefinedOptions?: IDateRange[];
  defaultOption?: IDateRange;
  setStart: (start: DateTime | null) => void;
  setEnd: (end: DateTime | null) => void;
}

const useStyles = makeStyles({
  pickerButton: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRight: "0px",
    borderRadius: "4px 0px 0px 4px",
    display: "flex",
    textTransform: "none",
  },
  pickerIcon: {
    margin: "6px",
    color: "rgba(0, 0, 0, 0.54)",
    width: "1.1em",
    height: "1.1em",
  },
  pickerContainer: {
    display: "flex",
    alignContent: "center",
    marginTop: "7px",
  },
  pickerLeftArrow: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "0px",
    transform: "rotate(180deg)",
  },
  pickerRightArrow: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderLeft: "0px",
    borderRadius: "0px 4px 4px 0px",
  },
  arrowIcon: {
    height: "1.1em",
    margin: "2px",
  },
  noBreak: {
    whiteSpace: "nowrap",
  },
});

export function RangeDatePicker(props: RangeDatePickerProps): JSX.Element {
  const { start, end, setStart, setEnd } = props;
  const [pickerDialogAnchor, setPickerDialogAnchor] = React.useState<HTMLElement | null>(null);
  const styles = useStyles();
  const handleOpenDatePicker = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setPickerDialogAnchor(event.currentTarget);
  }, []);

  const handleWeekForwardClick = React.useCallback(() => {
    if (toInterval(end, start).toDuration("weeks").toObject().weeks !== 1) {
      setEnd(start.plus({weeks: 1}));
    }
    setStart(start.plus({weeks: 1}));
    setEnd(end.plus({weeks: 1}));
  }, [end, start, setStart, setEnd]);

  const handleWeekBackwardClick = React.useCallback(() => {
    if (toInterval(end, start).toDuration("weeks").toObject().weeks !== 1) {
      setEnd(start.minus({weeks: 1}));
    }
    setStart(start.minus({weeks: 1}));
    setEnd(end.minus({weeks: 1}));
  }, [end, start, setStart, setEnd]);

  return (
    <>
      <Box className={styles.pickerContainer}>
        <Button
          onClick={handleOpenDatePicker}
          size={"large"}
          aria-haspopup="true"
          startIcon={<EventIcon className={styles.pickerIcon} />}
          className={styles.pickerButton}
        >
          <Typography>
            <span className={styles.noBreak}>{`${start.toFormat("LLL dd")} `}</span>
            {"- "}
            <span className={styles.noBreak}>{`${end.toFormat("LLL dd")}`}</span>
          </Typography>
        </Button>
        <IconButton
          size={"small"}
          className={styles.pickerLeftArrow}
          onClick={handleWeekBackwardClick}
        >
          <ArrowForwardIosIcon className={styles.arrowIcon} />
        </IconButton>
        <IconButton
          size={"small"}
          className={styles.pickerRightArrow}
          onClick={handleWeekForwardClick}
        >
          <ArrowForwardIosIcon className={styles.arrowIcon} />
        </IconButton>
      </Box>
      <RangeDatePickerDialog
        {...props}
        menuAnchor={pickerDialogAnchor}
        setMenuAnchor={setPickerDialogAnchor}
      />
    </>
  );
}
