import React from "react";
import "../app.css";

export default function LoadingSpinner(): JSX.Element {
  return (
    <>
      <div  className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}