import React, { ChangeEventHandler } from "react";
import { IconButton, makeStyles, Paper, TextField } from "@material-ui/core";
import { clsx } from "clsx";
import {
  SimpleActionTable,
  SimpleActionTableProps,
} from "../Display/Table/SimpleActionTable";
import { AddIcon } from "../../icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles({
  input: {
    width: "95%",
    margin: "5px 5px",
    minWidth: "10%",
  },
  topPad: {
    paddingTop: "5px",
  },
  fixedHeight: {
    maxHeight: "300px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  centeringContainer: {
    display: "block",
    margin: "auto",
  },
  rightPad: {
    paddingRight: "5px",
  },
});

export interface MultipleSingleValueInputProps<T>
  extends SimpleActionTableProps<T> {
  onInputChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onInputSubmit: () => void;
  inputString: string;
  inputError: boolean;
  inputLabel: string;
  tableContainerProps?: CSSProperties;
}

export function MultipleSingleValueInput<T>(
  props: MultipleSingleValueInputProps<T>
): JSX.Element {
  const {
    population,
    actionIcon,
    inputString,
    inputError,
    inputLabel,
    title,
    tableContainerProps,
    action,
    makeDisplay,
    onInputChange,
    onInputSubmit,
  } = props;
  const styles = useStyles();

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        onInputSubmit();
      }
    },
    [onInputSubmit]
  );
  return (
    <Paper>
      <SimpleActionTable
        title={title}
        population={population}
        action={action}
        actionIcon={actionIcon}
        makeDisplay={makeDisplay}
        tableContainerProps={tableContainerProps}
      />
      <div className={styles.flexRow}>
        <TextField
          className={styles.input}
          variant="outlined"
          label={inputLabel}
          value={inputString}
          onChange={onInputChange}
          error={inputError}
          onKeyDown={handleKeyDown}
        />
        <div
          className={clsx(
            styles.flexColumn,
            styles.centeringContainer,
            styles.rightPad
          )}
        >
          <IconButton color="primary" onClick={onInputSubmit}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </Paper>
  );
}
