import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IClassification } from "../common/interfaces";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  centering: {
    display: "block",
    margin: "auto",
    padding: "auto",
  },
  container: {},
});

interface IClassificationTableProps {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  classifications: { [id: string]: IClassification };
}

export default function ClassificationTable(
  props: IClassificationTableProps
): JSX.Element {
  const classes = useStyles();
  return (
    <Paper className={classes.centering}>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Grades</TableCell>
              <TableCell>Worktypes</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(props.classifications).map(([id, clss], index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {clss.name}
                </TableCell>
                <TableCell>
                  {Object.values(clss.grades)
                    .map((grade) => grade.name)
                    .join(", ")}
                </TableCell>
                <TableCell>{Object.entries(clss.workTypes).length}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => props.onEdit(id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => props.onDelete(id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
