import "./app.css";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import React from "react";
import Loading from "./components/loading";
import { createLogger } from "./components/Logging/Logging";
import { ErrorPage } from "./components/Display/ErrorPage";
import { Settings } from "luxon";
import Toaster, { ToastType } from "./common/Toaster";
import {
  app_loading_timeout_message,
  server_health_check_fail,
} from "./common/Messages";
import AppContextProvider from "./AppContextProvider";
import App from "./App";

const logger = createLogger("App");

async function isServerAlive(token: string) {
  try {
    let retries = 3;
    while (retries > 0) {
      logger.info("Checking server health...");
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/health-check`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.ok) {
        logger.info("Server OK");
        return true;
      }
      logger.info("Retrying...");
      retries -= 1;
    }
    return false;
  } catch (err) {
    logger.error("Server did not respond to health check", err);
    Toaster("Server did not respond to health check", ToastType.error);
    return false;
  }
}

const AppWrapper = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [view, setView] = React.useState<JSX.Element>(
    <Loading timeoutMessage={app_loading_timeout_message} />
  );

  React.useEffect(() => {
    Settings.defaultZoneName = "America/New_York";

    async function bootApplication() {
      const root = document.getElementById("splash");
      if (!root) {
        throw new Error("Could not find HTML element with id 'splash'");
      }

      const token = await getAccessTokenSilently({ timeoutInSeconds: 5 });
      const serverAlive = await isServerAlive(token);

      root.style.display = "none ";

      if (!serverAlive) {
        setView(<ErrorPage errorMessage={server_health_check_fail} />);
      } else {
        setView(
          <AppContextProvider token={token}>
            <App />
          </AppContextProvider>
        );
      }
    }

    bootApplication();
  }, [getAccessTokenSilently]);

  return view;
};

export default withAuthenticationRequired(AppWrapper);
