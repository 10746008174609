// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export enum ToastType {
  error,
  info,
  good,
}

const defaultOptons: ToastOptions = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default function Toaster(content: ToastContent, type?: ToastType): void {
  switch (type) {
    case ToastType.error:
      toast.error(<div>{content}<br/><br/>{"Tip: Sometimes refreshing the page can help."}</div>, 
        defaultOptons);
      break;
    case ToastType.info:
      toast.info(content, defaultOptons);
      break;
    case ToastType.good:
      toast.success(content, defaultOptons);
      break;
    default:
      toast(content, defaultOptons);
  }
}
