import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";

interface IPopoverDropdownProps<T> {
  listValues: T[];
  select: (selection: T) => void;
  makeDisplay?: (input: T) => string;
}

export default function PopoverDropdown<T>(
  props: IPopoverDropdownProps<T>
): JSX.Element {
  const { listValues, select, makeDisplay } = props;
  const [anchor, setAnchor] = React.useState<Element | undefined>(undefined);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchor(event.target as Element),
    []
  );
  const handleClose = React.useCallback(() => setAnchor(undefined), []);

  const makeSelection = React.useCallback(
    (input: T) => {
      select(input);
      handleClose();
    },
    [handleClose, select]
  );

  return (
    <div>
      <IconButton
        disabled={listValues.length === 0}
        color="primary"
        onClick={handleClick}
      >
        <AddIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {listValues.map((val, index) => (
          <MenuItem key={index} onClick={() => makeSelection(val)}>
            {makeDisplay ? makeDisplay(val) : val}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
