// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import "./index.css";
import { withErrorBoundary } from "./common/ErrorBoundary";
import AppWrapper from "./AppWrapper";

ReactDOM.render(
  withErrorBoundary(
    <Router>
      <Auth0ProviderWithHistory>
        <AppWrapper />
      </Auth0ProviderWithHistory>
    </Router>
  ),
  document.getElementById("root")
);
