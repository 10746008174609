import React, { ChangeEvent } from "react";
import { IStudent } from "../../common/interfaces";
import { DeleteIcon } from "../../icons";
import { MultipleSingleValueInput } from "../Input/MultipleSingleValueInput";

interface NewStudentInputProps {
  students: IStudent[];
  addStudent: (student: IStudent) => void;
  removeStudent: (student: IStudent) => void;
}

export function NewStudentInput(props: NewStudentInputProps): JSX.Element {
  const { students, addStudent, removeStudent } = props;
  const [newStudentName, setNewStudentName] = React.useState<string>("");

  const [nameError, setNameError] = React.useState<boolean>(false);

  const add = React.useCallback(() => {
    if (newStudentName.length === 0) {
      setNameError(true);
    }

    addStudent({ name: newStudentName, sasid: "000" });
    setNewStudentName("");
  }, [newStudentName, addStudent]);

  const onChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNewStudentName(e.target.value);
    },
    [setNewStudentName]
  );

  return (
    <div style={{ padding: "5px" }}>
      <MultipleSingleValueInput
        onInputChange={onChange}
        onInputSubmit={add}
        inputString={newStudentName}
        inputError={nameError}
        inputLabel="New Student Name"
        title={"Students"}
        population={students}
        makeDisplay={(s: IStudent) => s.name}
        action={removeStudent}
        actionIcon={<DeleteIcon />}
      />
    </div>
  );
}
