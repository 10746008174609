class Logger {
  namespace: string;
  constructor(namespace: string) {
    this.namespace = namespace;
  }

  log(message: string, level: string, data?: unknown) {
    console.log(
      JSON.stringify({
        namespace: this.namespace,
        level: level,
        timestamp: new Date().toISOString(),
        message: message,
        data: data,
      })
    );
  }

  info(message: string, data?: unknown) {
    this.log(message, "info", data);
  }

  error(message: string, data?: unknown) {
    this.log(message, "error", data);
  }

  fatal(message: string, data?: unknown) {
    this.log(message, "fatal", data);
  }

  fromError(error: unknown) {
    let message = "Derived from error";
    if (error instanceof Error) {
      message = error.message;
    }

    this.error(message, { error: error });
  }
}

function createLogger(namespace: string): Logger {
  return new Logger(namespace);
}

export { createLogger };
