import {
  IDateRange,
  ISchool,
  ITherapist,
  ITimesheetRecord,
  IWorkItem,
  IClassification,
} from "./interfaces";
import React from "react";
import formatDate, { prettyDuration } from "./DateFormat";
import FileSaver from "file-saver";
import { DateTime, Interval } from "luxon";

export function hasProp(obj: unknown, key: PropertyKey): boolean {
  return {}.hasOwnProperty.call(obj, key);
}

export function DateRange(
  title: string,
  start: DateTime,
  end: DateTime
): IDateRange {
  return { title, start, end };
}

export function isDateRange(
  range: IDateRange,
  start: DateTime,
  end: DateTime
): boolean {
  return range.start === start && range.end === end;
}

export function findDateRange(
  ranges: IDateRange[],
  start: DateTime,
  end: DateTime
): IDateRange | null {
  return (
    ranges.find(
      (range: IDateRange) => range.start === start && range.end === end
    ) ?? null
  );
}

export function toLocaleFormat(dateString: string): string {
  return DateTime.fromISO(dateString).toLocaleString();
}

export function buildWorkItemCSV(
  filteredWorkItems: IWorkItem[],
  schools: ISchool[],
  therapists: ITherapist[],
  classes: { [uuid: string]: IClassification }
): unknown[][] {
  return [
    ["sep=\t"],
    [
      "Item ID",
      "District",
      "Type",
      "Units",
      "DateTime Logged",
      "DateTime Worked",
      "Students",
      "Therapist",
    ],
    ...filteredWorkItems.map((item) => [
      item._id,
      schools.find((school) => school.id === item.schoolId)?.name ?? "unknown",
      classes[item.classId].workTypes[item.workTypeId],
      String(item.units),
      toLocaleFormat(item.dateLogged),
      toLocaleFormat(item.dateWorked),
      item.students.map((s) => s.name).join(", "),
      therapists.find((therp) => therp.userId === item.therapistUserId)?.name ??
        "unknown",
    ]),
  ];
}
export function buildTimeSheetCSV(
  sheets: ITimesheetRecord[],
  tname: string
): unknown[][] {
  return [
    [
      "Therapist Name",
      "DateTime of Service",
      "Time In",
      "Time Out",
      "Break Units",
      "Total (Hours)",
      "Miles",
    ],
    ...sheets.map((sheet) => {
      const clockIn = DateTime.fromISO(sheet.clockIn);
      const clockOut = DateTime.fromISO(sheet.clockOut);
      const dateOfService = getPeriodDateStringFormal(clockIn, clockOut);
      const duration = prettyDuration(
        clockIn,
        clockOut,
        parseInt(sheet.break_units ?? "0")
      );
      return [
        tname,
        dateOfService,
        getLongPrettyTime(clockIn),
        getLongPrettyTime(clockOut),
        sheet.break_units,
        duration,
        sheet.mileage,
      ];
    }),
  ];
}

export function downloadCSV(data: unknown[][], fname: string): void {
  const raw = data
    .map((row) => row.map((item) => `"${item}"`).join(","))
    .join("\n");
  const csv = new Blob([raw], { type: "text/csv;charset=utf-8;" });
  FileSaver.saveAs(csv, `${fname}.csv`);
}

export function unbreakableTextSegments(
  ...textSegments: string[]
): JSX.Element[] {
  return textSegments.map((text, index) => (
    <span key={`unbreakable-segment-${index}`} style={{ whiteSpace: "nowrap" }}>
      {text}
    </span>
  ));
}

export function startOfWeek(date: DateTime): DateTime {
  /* This version of luxon (^1.26.0) doen't have concept of a 
     locale specific start-day of the week, this function 
     American-izes startOf("week") to be Sunday 
  */
  return date.startOf("week").minus({ day: 1 });
}

export function endOfWeek(date: DateTime): DateTime {
  /* This version of luxon (^1.26.0) doen't have concept of a 
     locale specific end-day of the week, this function 
     American-izes endOf("week") to be Saturday 
  */
  return date.endOf("week").minus({ day: 1 });
}

export function startOfLastWeek(date: DateTime): DateTime {
  return startOfWeek(date.minus({ weeks: 1 }));
}

export function endOfLastWeek(date: DateTime): DateTime {
  return endOfWeek(date.minus({ weeks: 1 }));
}

export function toInterval(start: DateTime, end: DateTime): Interval {
  return Interval.fromDateTimes(start, end);
}

export function copy<T>(og: T | undefined): T {
  if (og === undefined) return {} as T;
  const cpy: T = { ...og };
  return cpy;
}

export function getSchoolName(schools: ISchool[], schoolId: string): string {
  return schools.find((school) => school.id === schoolId)?.name ?? "";
}

export function unitsToHours(units: number): number {
  return units / 4;
}

export function getContentType(res: Response): string | null {
  return res.headers.get("content-type");
}

export function isContentText(res: Response): boolean {
  return getContentType(res)?.includes("text/plain") ?? false;
}

export function isContentHTML(res: Response): boolean {
  return getContentType(res)?.includes("text/html") ?? false;
}

export function isContentJSON(res: Response): boolean {
  return getContentType(res)?.includes("application/json") ?? false;
}

export function isContentCSV(res: Response): boolean {
  return getContentType(res)?.includes("text/csv") ?? false;
}

export function getPrettyTime(date: DateTime): string {
  return date.toFormat("t") ?? "ERR";
}

export function getLongPrettyTime(date: DateTime): string {
  return date.toFormat("tt") ?? "ERR";
}

export function getPeriodDateStringReadable(
  start: DateTime,
  end: DateTime
): string {
  return start.day === end.day
    ? start.toLocaleString()
    : `${start.toFormat("LL dd, yy")} to ${end.toFormat("LLL dd, yy")}`;
}

export function getPeriodDateStringFormal(
  start: DateTime,
  end: DateTime
): string {
  return start.day === end.day
    ? start.toLocaleString()
    : `${formatDate(start, "LLL dd, yy")} to ${formatDate(end, "LLL dd, yy")}`;
}

export function getSchoolById(
  id: string,
  schools: ISchool[]
): ISchool | undefined {
  if (id === "All") return { id: "All", name: "All" } as ISchool;
  return schools.find((school) => school.id === id) ?? undefined;
}

export function getSchoolByName(
  name: string,
  schools: ISchool[]
): ISchool | undefined {
  if (name === "All") return { id: "All", name: "All" } as ISchool;
  return schools.find((school) => school.name === name) ?? undefined;
}

export function isObjectEmpty(obj: { [key: string]: unknown }): boolean {
  return Object.keys(obj).length === 0;
}
