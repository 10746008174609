import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { clsx } from "clsx";

export interface BillingActionsProps {
  invoiceDisabled?: boolean;
  csvDisabled?: boolean;
  invoiceAction: () => void;
  csvAction: () => void;
  hideCSV?: boolean;
}

const useStyles = makeStyles({
  paper: {
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  button: {
    marginTop: "10px",
    textTransform: "none",
  },
});

export function BillingActions(props: BillingActionsProps): JSX.Element {
  const { invoiceDisabled, csvDisabled, invoiceAction, csvAction } = props;
  const styles = useStyles();

  return (
    <Grid item xs={3}>
      <Paper className={clsx(styles.paper)}>
        <Typography variant="h5" component="h6">
          Actions
        </Typography>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          disabled={invoiceDisabled}
          onClick={invoiceAction}
        >
          Generate Invoice
        </Button>
        {!props.hideCSV && (
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={csvAction}
            disabled={csvDisabled}
          >
            Download CSV
          </Button>
        )}
      </Paper>
    </Grid>
  );
}
