import { Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { IValidationState } from "./WorkItemForm";
import { TypeUnitPairSelector } from "./TypeUnitPairSelector";
import { clsx } from "clsx";
import { DeleteIcon } from "../../icons";
import { IPartialWorkItem, IWorkType } from "../../common/interfaces";
import { SimpleActionTable } from "../Display/Table/SimpleActionTable";
import { OverrideScript } from "../../common/Script";
import { DateTime } from "luxon";
import { SettingsContext } from "../../context/SettingsContext";
import { isObjectEmpty } from "../../common/Utils";

export interface IWorkItemSelectorProps {
  dateWorked: DateTime;
  validationState: IValidationState;
  workTypes: { [uuid: string]: IWorkType };
  additionalItems: IPartialWorkItem[];
  classId: string;
  addAdditionalItem: (item: IPartialWorkItem) => void;
  removeAdditionalItem: (id: number) => void;
  setValidation: (key: keyof IValidationState, val: boolean) => void;
  setDateWorked: (DateTime: DateTime) => void;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "95%",
    margin: "5px 5px",
    minWidth: "10%",
  },
});

export function WorkItemSelector(props: IWorkItemSelectorProps): JSX.Element {
  const {
    validationState,
    workTypes,
    additionalItems,
    setValidation,
    addAdditionalItem,
    removeAdditionalItem,
    classId: classId,
  } = props;

  const { classes } = React.useContext(SettingsContext);

  const styles = useStyles();

  const script: OverrideScript<IPartialWorkItem> = [
    {
      id: "name",
      text: "Work Type",
      getField: (item: IPartialWorkItem) => {
        if (isObjectEmpty(classes)) return "";
        return classes[item.classId].workTypes[item.workTypeId].name;
      },
    },
    {
      id: "units",
      text: "Units",
      align: "right",
      getField: (item: IPartialWorkItem) => item.unit,
    },
    {
      isAction: true,
      id: "remove",
      text: "Remove Item",
      actionIcon: <DeleteIcon />,
      align: "right",
      action: (item: IPartialWorkItem) => removeAdditionalItem(item.id),
    },
  ];

  return (
    <Paper className={clsx(styles.container)}>
      <SimpleActionTable
        population={additionalItems}
        title={"Work Items"}
        makeDisplay={(item: IPartialWorkItem) =>
          isObjectEmpty(classes)
            ? ""
            : classes[item.classId].workTypes[item.workTypeId].name
        }
        overrideScript={script}
      />
      <TypeUnitPairSelector
        workTypes={workTypes}
        classId={classId}
        validationState={validationState}
        setValidation={setValidation}
        addAdditionalItem={addAdditionalItem}
        removeAdditionalItem={removeAdditionalItem}
        additionalItems={additionalItems}
      />
    </Paper>
  );
}
