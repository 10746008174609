import React, { CSSProperties } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableContainer } from "@material-ui/core";
import { clsx } from "../../../clsx";
import { IconType } from "../../../icons";
import { makeStyles } from "@material-ui/styles";
import { AlternateID, OverrideScript, WithIndex } from '../../../common/Script';
import { ScriptFactory } from '../../../common/Script';

export interface SimpleActionTableProps<T> {
  population: T[];
  title: string;
  action?: (student: T) => void;
  actionIcon?: IconType;
  makeDisplay?: (input: T) => string;
  overrideScript?: OverrideScript<T>;
  currentAlternate?: AlternateID;
  tableContainerProps?: CSSProperties;
}

const useStyles = makeStyles({
  table: {
  },
  label: {
    textAlign: 'center',
    fontSize: '12',
    width: '100%',
    margin: 'auto',
  },
  overflowAuto: {
    overflow: 'auto'
  },
});

export function SimpleActionTable<T>(props: SimpleActionTableProps<T>): JSX.Element {
  const {
    population,
    action,
    title,
    actionIcon,
    makeDisplay,
    overrideScript: overrideCells,
    currentAlternate
  } = props;
  const styles = useStyles();

  const defaultHeader = React.useMemo(() => (
    <TableHead >
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell align="right">Action</TableCell>
      </TableRow>
    </TableHead>
  ), [title]);

  const tableHeader = React.useMemo(() => {
    if (!overrideCells) return defaultHeader;
    return (
      <TableHead>
        <TableRow>
          {overrideCells.map((cell, index) => {
            const htmlProps = ScriptFactory.getHTMLProps(cell);
            return (
              <TableCell key={`header-${index}`} {...htmlProps}>{cell.text}</TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }, [defaultHeader, overrideCells]);

  const defaultBody = React.useMemo(() => {
    return (
      population.map((member, index) => (
        <TableRow key={index}>
          <TableCell
            component="th"
            scope="row"
          >
            {makeDisplay ? makeDisplay(member) : member}
          </TableCell>
          <TableCell align="right">
            <IconButton onClick={() => action?.(member)}>
              {actionIcon}
            </IconButton>
          </TableCell>
        </TableRow>
      ))
    );
  }, [action, actionIcon, makeDisplay, population]);

  const makeRow = React.useCallback((member: WithIndex<T>) => {
    if (!overrideCells) return;
    return (
      <TableRow key={`row-${member.index}`}>
        {overrideCells.map((ocell) => ScriptFactory.makeCell(ocell, member, currentAlternate))}
      </TableRow>
    );
  }, [currentAlternate, overrideCells]);

  const tableBody = React.useMemo(() => {
    if (!overrideCells) return defaultBody;
    return (
      population.map((member, index) =>({...member, index} as WithIndex<T>)).map(makeRow)
    );
  }, [defaultBody, makeRow, overrideCells, population]);

  return (
    <TableContainer style={props.tableContainerProps}>
      <Table stickyHeader className={clsx(styles.table)} size="small" aria-label="simple table">
        {tableHeader}
        <TableBody>
          {population.length > 0 ? (
            tableBody
          ) : (
            <TableRow>
              <TableCell colSpan={overrideCells?.length ?? 2}>
                {`No ${title.toLowerCase()}`}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function makeAlternate(alternate: string, index: number):
{ alternate: string, index: number}
{
  return { alternate, index};
}
