import { DateTime } from "luxon";

export function formatISO(date: DateTime): string {
  const dateString = date.toUTC().toISO();
  if (!dateString) return "";
  return dateString.substring(0, dateString.length - 5) + "Z";
}

export function formatDateString(dateString: string): string {
  const first = dateString.split(".")[0];
  return first + "Z";
}

export function getTimeZoneOffset(timeZone: string): number {
  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "short",
    timeZone: timeZone
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName")?.value;
  if (!timeZoneName) return -1;
  const offset = timeZoneName.slice(3);
  if (!offset) return 0;

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;

  const [, sign, hour, minute] = matchData;
  let result = parseInt(hour) * 60;
  if (sign === "+") result *= -1;
  if (minute) result += parseInt(minute);

  return result;
}

export function coerceDateToAmericaNewYorkTimeZone(date: Date): Date {
  const ms = date.valueOf();
  const localOffset = date.getTimezoneOffset();
  const newYorkOffset = getTimeZoneOffset("America/New_York");
  const diff = localOffset - newYorkOffset;
  const coercedDate = new Date(ms + (diff * 60000));
  return coercedDate;
}

