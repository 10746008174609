/* eslint-disable indent */
import React, { CSSProperties, Fragment } from "react";
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { getComparator, Order, stableSort } from "./Comparator";
import SortableTableHead from "./SortableTableHead";
import { SortableTableToolbar } from "./SortableTableToolbar";
import { filter } from "../../../common/Filtering";
import { AlternateID, OverrideScript, ScriptFactory } from "../../../common/Script";
import { NoTableContent } from "../NoTableContext";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 400,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      "&$hover:hover": {
        backgroundColor: "blue",
      },
    },
    densePaddingSwitch: {
      margin: "5px",
    },
  })
);

type FilterPredicate<T> = (member: T) => boolean;

interface SortableTableProps<T> {
  label: string;
  script: OverrideScript<T>;
  rows: T[];
  selected?: T[];
  showDelete?: boolean;
  hideStudents?: boolean;
  hideTherapist?: boolean;
  hideDensePaddingSwitch?: boolean;
  showActions?: boolean;
  defaultSortKey?: keyof T;
  defaultSortOrder?: "asc" | "desc";
  selectionFilters?: FilterPredicate<T>[];
  tableContainerProps?: CSSProperties;
  currentAlternate?: AlternateID;
  noContentText?: string;
  altToolbar?: JSX.Element;
  altFooter?: JSX.Element;
  densePadding?: boolean;
  hidePagination?: boolean;
  setSelected?: (selected: T[]) => void;
  canSelect?: (member: T) => boolean;
  onDelete?: () => void;
  canDelete?: (member: T) => boolean;
}

export default function SortableTable<T>(props: SortableTableProps<T>): JSX.Element {
  const { label, script, selected, rows, setSelected, canSelect, onDelete } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>(props.defaultSortOrder ?? "asc");
  const [orderBy, setOrderBy] = React.useState<keyof T | undefined>(props.defaultSortKey);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function handleRequestSort<K extends keyof T>(
    event: React.MouseEvent<unknown, MouseEvent>,
    property: K
  ) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected?.(filter<T>(rows, props.selectionFilters ?? []));
      return;
    }
    setSelected?.([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, item: T) => {
    if (setSelected && canSelect && selected) {
      if (!canSelect(item)) return;
      const selectedIndex = selected.indexOf(item);
      let newSelected: T[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = React.useCallback(
    (item: T) => {
      return selected?.includes(item) ?? false;
    },
    [selected]
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const sortedPaginatedRows = React.useMemo(() => {
    if (!orderBy) return rows;
    const sorted = stableSort<T>(rows, getComparator(order, orderBy, script));
    if (!sorted) return [];
    if (props.hidePagination) {
      return sorted;
    } else {
      return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
  }, [orderBy, rows, order, script, props.hidePagination, page, rowsPerPage]);

  const rowsWithIndices = React.useMemo(
    () => sortedPaginatedRows.map((member, index) => ScriptFactory.wrapIndex(member, index)),
    [sortedPaginatedRows]
  );

  const noContent = React.useMemo(() => rows.length === 0, [rows]);
  return (
    <Fragment>
      <SortableTableToolbar
        label={label}
        numSelected={selected?.length}
        onDelete={onDelete}
        showDelete={props.showDelete ?? false}
        useStyles={useToolbarStyles}
        altToolbar={props.altToolbar}
      />
      <TableContainer style={props.tableContainerProps}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size={props.densePadding || dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <SortableTableHead
            useStyles={useStyles}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            script={script}
          />
          <TableBody>
            {noContent ? (
              <NoTableContent numCols={script.length} text={props.noContentText} />
            ) : (
              <>
                {rowsWithIndices?.map((row, rowIndex) => {
                  let canDelete = false;
                  if (props.canDelete && props.canDelete(row)) {
                    canDelete = true;
                  }
                  const isItemSelected = isSelected(row);
                  return (
                    <TableRow
                      hover
                      onClick={(event: React.MouseEvent<unknown, MouseEvent>) => {
                        if (canDelete) {
                          handleClick(event, row);
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={rowIndex.toString()}
                      selected={isItemSelected}
                      className={classes.tableRow}
                    >
                      {script.map((verse) => {
                        return ScriptFactory.makeCell<T>(verse, row, props.currentAlternate);
                      })}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (props.densePadding || dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={script.length} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.altFooter ? (
        props.altFooter
      ) : (
        <>
          {!props.hidePagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {!props.hideDensePaddingSwitch && (
            <FormControlLabel
              className={classes.densePaddingSwitch}
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          )}
        </>
      )}
    </Fragment>
  );
}
