import React from 'react';
import { ITherapist, ModalProps } from '../common/interfaces';
import WorkItemForm from './Input/WorkItemForm';
import { DefaultModal } from './Display/DefaultModal';

interface IWorkItemFormModalProps extends ModalProps {
  therapist: ITherapist;
}

export default function WorkItemFormModal(props: IWorkItemFormModalProps): JSX.Element {
  const { open, setOpen, therapist } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DefaultModal 
      open={open}
      onClose={handleClose}   
    >
      <WorkItemForm therapist={therapist} setOpen={setOpen} />
    </DefaultModal>
  );
}
