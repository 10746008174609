import React from "react";
import { useIsMounted } from "../common/useIsMounted";
import Loading from "../components/loading";

interface ITokenContext {
  token: string;
}

const defaultState: ITokenContext = {
  token: "",
};

export const TokenContext = React.createContext<ITokenContext>(defaultState);

export default function TokenContextProvider(
  props: React.PropsWithChildren<ITokenContext>
): JSX.Element {
  const [state, setState] = React.useState<ITokenContext>(defaultState);
  const isMounted = useIsMounted();

  React.useEffect(() => {
    if (!props) return;
    if (isMounted) {
      setState({ token: props.token });
    }
  }, [isMounted, props]);

  return (
    <TokenContext.Provider value={state}>
      {state.token === "" ? <Loading /> : props.children}
    </TokenContext.Provider>
  );
}
