/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, makeStyles, TextField } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import DropdownSelect from "./DropdownSelect";
import { clsx } from "clsx";
import { IValidationState } from "./WorkItemForm";
import {
  DropdownSelectorOption,
  IPartialWorkItem,
  IWorkType,
} from "../../common/interfaces";
import { isObjectEmpty } from "../../common/Utils";

interface ITypeUnitPairSelectorProps {
  workTypes: { [uuid: string]: IWorkType };
  validationState: IValidationState;
  additionalItems: IPartialWorkItem[];
  classId: string;
  setValidation: (key: keyof IValidationState, val: boolean) => void;
  addAdditionalItem: (item: IPartialWorkItem) => void;
  removeAdditionalItem: (id: number) => void;
}

const useStyles = makeStyles({
  centeringRow: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    gap: "5px",
    maxWidth: "400px",
  },
  spacer: {
    width: "18px",
    display: "inline-block",
  },
  input: {
    width: "90%",
    margin: "5px auto",
    minWidth: "10%",
  },
  autoMargin: {
    margin: "auto",
  },
  dropdownColumn: {
    flex: "70%",
    marginRight: "5px",
    marginLeft: "5px",
  },
  unitPickerColumn: {
    flex: "15%",
    minWidth: "60px",
  },
  buttonColumn: {
    flex: "5%",
    margin: "auto",
  },
});

export function TypeUnitPairSelector(
  props: ITypeUnitPairSelectorProps
): JSX.Element {
  const { workTypes, classId } = props;
  const styles = useStyles();
  const [workTypeId, setWorkTypeId] = React.useState<string | undefined>(
    undefined
  );
  const [units, setUnits] = React.useState<number>(0);
  const [itemsCreated, setItemsCreated] = React.useState<number>(0);
  const incrementItemsCreated = () => setItemsCreated((prev) => prev + 1);
  const errorTypeSelect = React.useRef<boolean>(false);
  const errorUnits = React.useRef<boolean>(false);

  const handleAdd = () => {
    const id = itemsCreated;
    if (workTypeId === undefined) {
      errorTypeSelect.current = true;
      return;
    }
    if (units === 0) {
      errorUnits.current = true;
      return;
    }

    const item: IPartialWorkItem = {
      classId,
      workTypeId,
      unit: units,
      id: id,
    };
    props.addAdditionalItem(item);
    incrementItemsCreated();
    setWorkTypeId(undefined);
    setUnits(0);
  };

  const workTypeOptions = React.useMemo(
    () =>
      Object.entries(workTypes)
        .sort((a, b) => a[1].name.localeCompare(b[1].name))
        .map(([id, workType]) => ({
          label: workType.name,
          value: id,
        })),
    [workTypes]
  );

  const handleWorkTypeChange = React.useCallback(
    (e: DropdownSelectorOption) => {
      errorTypeSelect.current = false;
      setWorkTypeId(e.value);
    },
    []
  );

  return (
    <div className={clsx(styles.centeringRow)}>
      <div
        style={{
          width: "400px",
        }}
      >
        <DropdownSelect
          value={{
            label:
              workTypeId === undefined || isObjectEmpty(workTypes)
                ? ""
                : workTypes[workTypeId].name,
            value: workTypeId ?? "",
          }}
          handleChange={(ns) => {
            if (ns) handleWorkTypeChange(ns);
          }}
          options={workTypeOptions}
          className={styles.input}
        />
      </div>
      <TextField
        label="Units"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ inputProps: {} }}
        value={units}
        variant="outlined"
        className={clsx(styles.input, styles.unitPickerColumn)}
        required
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          if (event?.target?.value) {
            errorUnits.current = false;
            setUnits(parseInt(event.target.value));
          }
        }}
        error={errorUnits.current}
      />
      <div className={styles.buttonColumn}>
        <Button color="primary" variant="outlined" onClick={handleAdd}>
          <b>Add</b>
        </Button>
        {/* <IconButton color="primary" onClick={handleAdd}>
          <AddIcon />
        </IconButton> */}
      </div>
    </div>
  );
}
