import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import React from 'react';

export default function PageTabs({ selectedPage, setSelectedPage }) {
  const mainListItems = React.useMemo(
    () => (
      <div>
        <ListItem
          button
          selected={selectedPage === '/billing'}
          component={Link}
          to={'/billing'}
          onClick={() => setSelectedPage('/billing')}
        >
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>

        <ListItem
          button
          selected={selectedPage === '/districts'}
          component={Link}
          to={'/districts'}
          onClick={() => setSelectedPage('/districts')}
        >
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Districts" />
        </ListItem>

        <ListItem
          button
          selected={selectedPage === '/invoices'}
          component={Link}
          to={'/invoices'}
          onClick={() => setSelectedPage('/invoices')}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>

        <ListItem
          button
          selected={selectedPage === '/staff'}
          component={Link}
          to={'/staff'}
          onClick={() => setSelectedPage('/staff')}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Staff" />
        </ListItem>

        <ListItem
          button
          selected={selectedPage === '/services'}
          component={Link}
          to={'/services'}
          onClick={() => setSelectedPage('/services')}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Services" />
        </ListItem>
      </div>
    ),
    [selectedPage, setSelectedPage],
  );
  return mainListItems;
}
