import { ISchool, ModalProps } from "../common/interfaces";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { DefaultModal } from "./Display/DefaultModal";
import { SchoolEditModalControls } from "./SchoolEditModalControl";

interface ISchoolEditModalProps extends ModalProps {
  school: ISchool | undefined;
  handleExit: () => void;
  handleSave: (newSchool: ISchool) => void;
}

export default function SchoolEditModal(
  props: ISchoolEditModalProps
): JSX.Element {
  const { open, setOpen, school, handleExit, handleSave } = props;

  const close = React.useCallback(() => {
    setOpen(false);
    handleExit();
  }, [handleExit, setOpen]);

  return (
    <DefaultModal open={open} onClose={close}>
      <SchoolEditModalControls
        school={school}
        handleExit={close}
        handleSave={handleSave}
      />
    </DefaultModal>
  );
}
