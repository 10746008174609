/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { ErrorPage } from "../components/Display/ErrorPage";
import { createLogger } from "../components/Logging/Logging";
const logger = createLogger("ErrorBoundary");

interface ErrorBoundaryProps {
  hasError: boolean;
  error: unknown;
}

function errorWrap() {
  return <ErrorPage />;
}

export default class ErrorBoundary extends React.Component {
  state: ErrorBoundaryProps;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined };
    window.addEventListener("error", (event) => {
      this.setState({ hasError: true, error: event });
      logger.error("Functional Error", {
        error: event.message,
        stack: event.error?.stack ?? "event.error is undefined",
      });
    });
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error, renderingError: true };
  }
  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.error("Rendering Error", { error, errorInfo });
  }
  render() {
    const root = document.getElementById("splash");
    if (root) root.style.display = "none ";
    if (this.state.hasError) {
      return errorWrap();
    }
    try {
      // eslint-disable-next-line react/prop-types
      return this.props.children;
    } catch {
      return errorWrap();
    }
  }
}

export function withErrorBoundary(element: JSX.Element) {
  return <ErrorBoundary>{element}</ErrorBoundary>;
}
