import React from "react";
import {
  IEditByID,
  IInvoiceObject,
  ISchool,
  ITherapist,
  ITimesheetRecord,
  IWorkItem,
  IClassWithoutWorkTypesOrGrades,
} from "../common/interfaces";
import { hasProp } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { TokenContext } from "./TokenContext";

interface MultiIdPayload {
  idsArray: string[];
}

export type PostPayload =
  | IEditByID
  | ISchool
  | ITherapist
  | string
  | IInvoiceObject
  | ITimesheetRecord
  | IWorkItem[]
  | string[]
  | MultiIdPayload
  | IClassWithoutWorkTypesOrGrades
  | { prefix: string }
  | { [key: string]: unknown[] };

export type GetPayload = { [key: string]: string | number | boolean };
export type DeletePayload = GetPayload;

export type GetCall = (
  payload: GetPayload,
  ...urlParams: string[]
) => Promise<Response>;
export type PostCall = (
  payload: PostPayload,
  ...urlParams: string[]
) => Promise<Response>;
export type DeleteCall = (...urlParams: string[]) => Promise<Response>;

interface IAPIContext {
  getSchools: GetCall;
  getWorkItems: GetCall;
  getTherapists: GetCall;
  getTherapistByID: GetCall;
  getSettings: GetCall;
  getPublicSettings: GetCall;
  getInvoice: GetCall;
  incrementInvoiceId: PostCall;
  getIsInvoiceNumberUnique: GetCall;
  getNextInvoiceId: GetCall;
  getInvoiceList: GetCall;
  getInvoiceById: GetCall;
  getInvoiceExportCSV: PostCall;
  deleteInvoice: GetCall;
  getTimesheets: GetCall;
  getDeadline: GetCall;
  editSchool: PostCall;
  deleteSchools: PostCall;
  storeSchool: PostCall;
  storeWorkItem: PostCall;
  deleteWorkItems: PostCall;
  editWorkItem: PostCall;
  storeTherapist: PostCall;
  deleteTherapist: PostCall;
  editTherapist: PostCall;
  configuration: {
    classes: {
      get: GetCall;
      add: PostCall;
      update: PostCall;
      delete: DeleteCall;
      grades: {
        add: PostCall;
        update: PostCall;
        delete: DeleteCall;
      };
      workTypes: {
        add: PostCall;
        update: PostCall;
        delete: DeleteCall;
      };
    };
  };
  storeInvoiceObject: PostCall;
  editTimesheet: PostCall;
  storeTimesheetRecord: PostCall;
  deleteTimesheetRecord: PostCall;
}

const emptyAPICall = () =>
  new Promise<Response>((resolve) => {
    resolve(new Response("empty"));
  });

const baseURL = process.env.REACT_APP_SERVER_URL;
const defaultState: IAPIContext = {
  getSchools: emptyAPICall,
  getWorkItems: emptyAPICall,
  getTherapists: emptyAPICall,
  getTherapistByID: emptyAPICall,
  getSettings: emptyAPICall,
  getPublicSettings: emptyAPICall,
  getInvoice: emptyAPICall,
  deleteInvoice: emptyAPICall,
  incrementInvoiceId: emptyAPICall,
  getIsInvoiceNumberUnique: emptyAPICall,
  getNextInvoiceId: emptyAPICall,
  getInvoiceList: emptyAPICall,
  getInvoiceById: emptyAPICall,
  getInvoiceExportCSV: emptyAPICall,
  getTimesheets: emptyAPICall,
  getDeadline: emptyAPICall,
  editSchool: emptyAPICall,
  deleteSchools: emptyAPICall,
  storeSchool: emptyAPICall,
  storeWorkItem: emptyAPICall,
  deleteWorkItems: emptyAPICall,
  editWorkItem: emptyAPICall,
  storeTherapist: emptyAPICall,
  deleteTherapist: emptyAPICall,
  editTherapist: emptyAPICall,
  configuration: {
    classes: {
      get: emptyAPICall,
      add: emptyAPICall,
      update: emptyAPICall,
      delete: emptyAPICall,
      grades: {
        add: emptyAPICall,
        update: emptyAPICall,
        delete: emptyAPICall,
      },
      workTypes: {
        add: emptyAPICall,
        update: emptyAPICall,
        delete: emptyAPICall,
      },
    },
  },
  storeInvoiceObject: emptyAPICall,
  editTimesheet: emptyAPICall,
  storeTimesheetRecord: emptyAPICall,
  deleteTimesheetRecord: emptyAPICall,
};

export const API = React.createContext<IAPIContext>(defaultState);

const logger = createLogger("APIContext");

export default function APIContextProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const { token } = React.useContext(TokenContext);

  const [state, setState] = React.useState<IAPIContext>(defaultState);

  const serializeQueryParams = (
    params:
      | {
          [key: string]: string | number | boolean;
        }
      | GetPayload
  ) => {
    const queryArray = [];
    for (const key in params) {
      if (hasProp(params, key)) {
        queryArray.push(`${key}=${encodeURIComponent(params[key])}`);
      }
    }
    const final = queryArray.join("&");
    return final;
  };

  const get = React.useCallback(
    async (endpoint: string, extra?: { [key: string]: unknown }) => {
      logger.info(`GET ${endpoint}`, extra);
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
        ...extra,
      });
    },
    [token]
  );

  const _delete = React.useCallback(
    async (endpoint: string, extra?: { [key: string]: unknown }) => {
      logger.info(`GET ${endpoint}`, extra);
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
        mode: "cors",
        ...extra,
      });
    },
    [token]
  );

  const post = React.useCallback(
    async (
      endpoint: string,
      body?: PostPayload,
      extra?: { [key: string]: unknown }
    ) => {
      logger.info(`POST ${endpoint}`, { body, extra });
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(body),
        ...extra,
      });
    },
    [token]
  );

  React.useEffect(
    () =>
      setState({
        /* School Functions */

        getSchools: async (): Promise<Response> => {
          return await get("/retrieve-school");
        },
        editSchool: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as IEditByID;
          delete editById.updateObj.id;
          return await post("/edit-school", payload);
        },
        deleteSchools: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-school", {
            idsArray: payload as string[],
          });
        },
        storeSchool: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-school", payload);
        },

        /* Work Item Functions */

        getWorkItems: async (): Promise<Response> => {
          return await get(`/retrieve-work-item-interval`);
        },
        storeWorkItem: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-work-item", payload);
        },
        deleteWorkItems: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-work-item", {
            idsArray: payload as string[],
          });
        },
        editWorkItem: async (payload: PostPayload): Promise<Response> => {
          return await post("/edit-work-item", payload);
        },

        /* Therapist Functions */

        getTherapists: async (): Promise<Response> => {
          return await get("/retrieve-therapist");
        },
        getTherapistByID: async (payload: GetPayload): Promise<Response> => {
          return await get(
            `/retrieve-user-info?${serializeQueryParams(payload)}`
          );
        },

        storeTherapist: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-therapist", payload);
        },
        deleteTherapist: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-therapist", {
            idsArray: payload as string[],
          });
        },
        editTherapist: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as IEditByID;
          delete editById.updateObj._id;
          return await post("/edit-therapist", payload);
        },

        /* Settings Functions */

        getSettings: async (): Promise<Response> => {
          return await get("/retrieve-settings");
        },
        getPublicSettings: async (): Promise<Response> => {
          return await get("/retrieve-public-settings");
        },

        /* Settings Configuration Functions */

        configuration: {
          classes: {
            get: async (): Promise<Response> => {
              return await get("/configuration/classes");
            },

            add: async (payload: PostPayload): Promise<Response> => {
              return await post("/configuration/classes", payload);
            },

            update: async (
              payload: PostPayload,
              classId: string
            ): Promise<Response> => {
              return await post(`/configuration/classes/${classId}`, payload);
            },

            delete: async (classId: string): Promise<Response> => {
              return await _delete(`/configuration/classes/${classId}`);
            },

            grades: {
              add: async (
                payload: PostPayload,
                classId: string
              ): Promise<Response> => {
                return await post(
                  `/configuration/classes/${classId}/grades`,
                  payload
                );
              },

              update: async (
                payload: PostPayload,
                classId: string,
                gradeId: string
              ) => {
                return await post(
                  `/configuration/classes/${classId}/grades/${gradeId}`,
                  payload
                );
              },

              delete: async (classId: string, gradeId: string) => {
                return await _delete(
                  `/configuration/classes/${classId}/grades/${gradeId}`
                );
              },
            },

            workTypes: {
              add: async (
                payload: PostPayload,
                classId: string
              ): Promise<Response> => {
                return await post(
                  `/configuration/classes/${classId}/work-types`,
                  payload
                );
              },

              update: async (
                payload: PostPayload,
                classId: string,
                workTypeId: string
              ) => {
                return await post(
                  `/configuration/classes/${classId}/work-types/${workTypeId}`,
                  payload
                );
              },

              delete: async (classId: string, workTypeId: string) => {
                return await _delete(
                  `/configuration/classes/${classId}/work-types/${workTypeId}`
                );
              },
            },
          },
        },

        /* Invoice Functions */

        getInvoice: async (payload: GetPayload): Promise<Response> => {
          return await get(
            `/generate-invoice?${serializeQueryParams(payload)}`
          );
        },
        getIsInvoiceNumberUnique: async (
          payload: GetPayload
        ): Promise<Response> => {
          return await get(
            `/invoice-no-unique?${serializeQueryParams(payload)}`
          );
        },
        getNextInvoiceId: async (payload: GetPayload): Promise<Response> => {
          return await get(`/next-invoiceid?${serializeQueryParams(payload)}`);
        },
        getInvoiceList: async (): Promise<Response> => {
          return await get("/get-invoice-list");
        },
        getInvoiceById: async (params: GetPayload): Promise<Response> => {
          return await get(`/get-invoice-byid?${serializeQueryParams(params)}`);
        },
        storeInvoiceObject: async (payload: PostPayload): Promise<Response> => {
          return await post("/post-invoice-object", payload);
        },
        incrementInvoiceId: async (payload: PostPayload): Promise<Response> => {
          return await post(`/increment-invoiceid`, payload);
        },
        getInvoiceExportCSV: async (params: PostPayload): Promise<Response> => {
          return await post(`/export-invoice-to-quickbooks`, params);
        },

        deleteInvoice: async (params: GetPayload): Promise<Response> => {
          return await _delete(
            `/revert-invoice?${serializeQueryParams(params)}`
          );
        },

        /* Timesheet Functions */

        getTimesheets: async (): Promise<Response> => {
          return await get("/retrieve-timesheet");
        },
        editTimesheet: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as IEditByID;
          delete editById.updateObj._id;
          return await post("/edit-timesheet", payload);
        },
        storeTimesheetRecord: async (
          payload: PostPayload
        ): Promise<Response> => {
          return await post("/store-timesheet", payload);
        },
        deleteTimesheetRecord: async (
          payload: PostPayload
        ): Promise<Response> => {
          return await post("/delete-timesheet", payload);
        },
        getDeadline: async (): Promise<Response> => {
          return await get("/timesheet-deadline");
        },
      }),
    [_delete, get, post]
  );

  return <API.Provider value={state}>{props.children}</API.Provider>;
}
